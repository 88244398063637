import { Linking, Platform } from 'react-native';
import * as AuthSession from 'expo-auth-session';
import AsyncStorage from '@react-native-async-storage/async-storage';

const loginUrl = 'https://mobile-churchofengland.cs121.force.com/';
const endpointDev = 'https://efdev-churchofengland.cs86.force.com/synod';
const endpointProd = 'https://churchofengland.force.com';

const clientId = '3MVG9sh10GGnD4DutP5NQyGjKGW_paJ3KVtr7ANzIKwvfToH38kwsKZhi7_0gGCqJTVaOrX5rDYLdFbUlWmCS';
const useProxy = true;

const AuthenticationController = {
  getServerURL() {
    // Swap to localhost to use local server
    return "https://api.generalsynodapp.com"
    return 'http://localhost:8005';
    
  },

  async getUserData(user, token) {
    try {
      __DEV__ && console.log(token);
      __DEV__ && console.log(this.getServerURL());
      const url = `${this.getServerURL()}/synod/salesforce/getUserData`;

      //console.log("url",url)


      let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          IsProd: true,
          Token: token
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      __DEV__ && console.log('getUserData Response', response);

      if (response.status === 200) {
        let json = await response.json();

        __DEV__ && console.log(json);
        return json;
      } else if (response.status === 403 || response.status === 401) {
        const refreshToken = await AsyncStorage.getItem('refreshToken');
        const result = await refresh(config, {
          refreshToken: refreshToken,
        });

      } else {
        let json = await response.json();
        __DEV__ && console.log("json",json);
        return null;
      }
    } catch (error) {
      console.log('getUserData Error', error);
    }
  },

  async checkIfSynodMember(accessToken, userId) {
    try {
      const url = `${this.getServerURL()}/synod/salesforce/checkIfSynodMember`;
      const bearer = 'Bearer ' + accessToken;

      __DEV__ && console.log(url, accessToken, userId);

      let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          IsProd: true,
          Token: accessToken,
          ID: userId,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      __DEV__ && console.log('checkIfSynodMember1', response);

      if (response.status === 200) {
        let json = await response.json();

        __DEV__ && console.log('checkIfSynodMember2', json);
        return json.body.records[0].Contact.General_Synod_member__c;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};

export default AuthenticationController;

