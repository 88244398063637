import React, { useEffect, useState, useRef } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
  StyleSheet,
  Dimensions,
  ScrollView,
  FlatList,
  Alert,
  TextInput,
  BackHandler,
  Keyboard,
  ActivityIndicator,
} from 'react-native';
import { isTablet } from 'react-native-device-info';
import { EventRegister } from 'react-native-event-listeners';
import { isIphoneX } from 'react-native-iphone-x-helper';
import RNModal from 'react-native-modal';
import DataController from '../controllers/DataController';
import _ from 'lodash';
import images from '../resources/images';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import Popover from 'react-native-popover-view';
// import axios from 'axios';
import DocumentShareMenu from './DocumentShareMenu';
import PDFAnnotationModal from '../components/PDFAnnotationModal';
import AuthenticationController from '../controllers/AuthenticationController';
import ModalWeb from 'react-native-modal';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const viewConfigRef = {
  viewAreaCoveragePercentThreshold: 50,
};

const PDFViewerScreen = ({ navigation, route, screenProps, visible, close, user, userInfo, logout }) => {
  const moreButton = useRef(null);
  const pdfFlatList = useRef(null);
  const pdfFlatListPaging = useRef(null);

  const [_document, setDocument] = useState(route.params ? route.params.document : null);
  const [pdfKey, setPdfKey] = useState(0);
  const [pagingEnabled, setPagingEnabled] = useState(false);
  const [isBookmarked, setIsBookMarked] = useState(false);
  const [hasNote, setHasNote] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [initialPageNumber, setInitialPageNumber] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(_document.Pages);
  const [shareMenuVisible, setShareMenuVisible] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState('');
  const [notes, setNotes] = useState([]);
  const [pdfData, setPdfDate] = useState(_document['Text Content'] ? _document['Text Content'] : []);
  const [filteredSearch, setFilteredSearch] = useState([]);
  const [pdf, setPdf] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: Dimensions.get('window').height - 113,
    width: Dimensions.get('window').width - 20,
  })

  useEffect(() => {
    let bookmarkIconSource = images.Button_Bookmark_Inactive;
    let noteIconSource = images.Button_Note_Add;
    let pagingIconSource = images.Button_Paging;
    let searchIconSource = images.Button_PageSearch_Inactive;

    if (isBookmarked) {
      bookmarkIconSource = images.Button_Bookmark_Active;
    }

    if (hasNote) {
      noteIconSource = images.Button_Note_Edit;
    }

    if (pagingEnabled) {
      pagingIconSource = images.Button_Paging;
    }

    if (searchActive) {
      searchIconSource = images.Button_PageSearch_Active;
    }

    const rightButtonsLayout = () => (
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity style={{ height: 44, width: 44, alignContent: 'center', justifyContent: 'center', marginHorizontal: 8, }} onPress={() => toggleSearch()}>
          <Image resizeMode="contain" style={styles.icon} source={searchIconSource} />
        </TouchableOpacity>
        <TouchableOpacity style={{ height: 44, width: 44, alignContent: 'center', justifyContent: 'center', marginHorizontal: 8, }} onPress={() => toggleBookmark()}>
          <Image resizeMode="center" style={styles.icon} source={bookmarkIconSource} />
        </TouchableOpacity>
        <TouchableOpacity style={{ height: 44, width: 44, alignContent: 'center', justifyContent: 'center', marginHorizontal: 8, }} onPress={() => setShareMenuVisible(true)} ref={moreButton}>
          <Image resizeMode="center" style={styles.icon} source={images.Button_More} />
        </TouchableOpacity>
      </View>
    );

    navigation.setOptions({
      title: pageNumber + ' of ' + numberOfPages,
      headerStyle: {
        backgroundColor: '#382E73',
        borderBottomWidth: 0,
        height: Platform.OS === 'web' ? 44 : undefined,
      },
      headerBackTitle: 'Back',
      headerTintColor: '#fff',
      headerRight: rightButtonsLayout,
      headerTitleAlign: 'center',
      headerTitleStyle: {
        fontFamily: 'Inter-SemiBold',
        fontSize: 17,
        fontWeight: Platform.OS === 'android' ? 'normal' : '600',
        letterSpacing: -0.2,
        color: '#F1F3F5',
        marginHorizontal: 0,
        paddingHorizontal: 0
      },
    });
  }, [
    navigation,
    searchActive,
    route,
    setShareMenuVisible,
    toggleSearch,
    toggleBookmark,
    isBookmarked,
    hasNote,
    pagingEnabled,
    pageNumber,
    numberOfPages,
  ]);

  useEffect(() => {
    const getLastViewedPage = async () => {
      const _pageNumber = await DataController.getLastPage(_document.id);
      setInitialPageNumber(_pageNumber);
    };

    const checkForNote = async () => {
      let note = await DataController.getNoteForDocument(_document);
      let _hasNote = false;

      if (note) {
        _hasNote = true;
      }

      let _isBookMarked = await DataController.isDocumentBookmarked(_document);

      setHasNote(_hasNote);
      setIsBookMarked(_isBookMarked);
    };

    EventRegister.addEventListener('pdf/go-to-page', ({ pageNumber }) => {
      setModalVisible(false);
    });

    getLastViewedPage();
    checkForNote();
    getAllNotes();
    getDocument();

    EventRegister.addEventListener('notes/notes-updated', () => getAllNotes());

    return () => {
      EventRegister.removeEventListener('pdf/go-to-page');
      EventRegister.removeEventListener('notes/notes-updated');
    };
  }, []);

  const toggleBookmark = async () => {
    let _isBookMarked = await DataController.isDocumentBookmarked(_document);

    if (_isBookMarked) {
      await DataController.deleteBookmarkForDocument(_document);
    } else {
      await DataController.bookmarkDocument(_document);
    }

    setIsBookMarked(!_isBookMarked);
  };

  const toggleSearch = () => {
    let _searchActive = !searchActive;

    setSearchActive(_searchActive);

    if (!_searchActive) {
      setSearch('');
      setFilteredSearch([]);
    }
  };

  const getDocument = async () => {
    try {
      let result = await fetch(
        `${AuthenticationController.getServerURL()}/synod/getDocument`,
        {
          method: 'POST',
          body: JSON.stringify({
            URL: _document['Website URL']
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });

      const json = await result.json();

      setPdf(json.blob);
      setPages(Array.from({ length: numberOfPages }, (_, i) => i + 1));
      setPdfKey(pdfKey + 1);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllNotes = () => {
    DataController.getAllNotes().then((_notes) => {
      if (_document) {
        let notesForDocument = _notes.find((_note) => _note.documentId === _document.id);

        if (notesForDocument) {
          notesForDocument.notes = _.orderBy(notesForDocument.notes, ['page', 'updated'], ['asc', 'desc']);

          setNotes(notesForDocument.notes);
        } else {
          setNotes([]);
        }
      }
    })
  };

  const openNotes = () => {
    EventRegister.emit('pdf/open-list', { id: _document.id, doc: _document });

    setTimeout(() => {
      setModalVisible(true);
    }, 100);
  };

  const addNoteToPage = (_pageNumber) => {
    EventRegister.emit('pdf/open-page-annotation', { pageNumber: pageNumber, id: _document.id, doc: _document });

    setTimeout(() => {
      setModalVisible(true);
    }, 100);
  };

  const numberOfNotes = notes.filter(_note => _note.page === pageNumber).length;

  const renderItem = ({ item, index, separators }) => {
    return (
      <View style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', height: pagingEnabled ? dimensions.height : null, }}>
        <View style={{ backgroundColor: '#FFFFFF', alignSelf: 'center', borderWidth: 1, borderColor: 'rgba(0, 0,0,0.1)', }}>
          <Page
            pageNumber={item}
            loading={() => (
              <ActivityIndicator style={{ alignSelf: 'center' }} color="#382E73" />
            )}
          />
        </View>
      </View>
    );
  };

  const closeShareMenuPopover = () => setShareMenuVisible(false);

  const togglePaging = () => {
    const _pagingEnabled = !pagingEnabled;

    setPagingEnabled(_pagingEnabled);
    setPdfKey(pdfKey + 1);
    navigation.setParams({ pagingEnabled: _pagingEnabled });
    if (_pagingEnabled) {
      pdfFlatList.current.scrollToIndex({ index: pageNumber - 1, animated: false, viewPosition: 0.5 })
    }
  };

  const height = Dimensions.get('window').height - 113;
  const width = Dimensions.get('window').width - 20;

  const measureScrollBarWidth = () => {
    // Create the measurement node
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);
    const inner = document.createElement('div');
    outer.appendChild(inner);
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  };

  const measureContainer = (event) => {
    setDimensions({
      height: event.nativeEvent.layout.height,
      width: event.nativeEvent.layout.width - measureScrollBarWidth(),
      // width: event.nativeEvent.layout.width - 20,
    })
  };

  const toggleModal = () => {
    getAllNotes();
    setModalVisible(!modalVisible);
  };

  const clearSearch = () => {
    setSearch('');
    setFilteredSearch([]);
  };

  const pagePressed = (_pageNumber) => {
    try {
      pdfFlatList.current.scrollToIndex({ animated: false, index: _pageNumber - 1, viewPosition: 0 });
      closeSearch();
    } catch (error) {
      console.log(error);
    }
  };

  const closeSearch = () => {
    setSearch('');
    setSearchActive(false);
    setFilteredSearch([]);
  };

  const searchChanged = (input) => {
    if (input !== '') {
      const array = pdfData.filter((item) => item.text.toUpperCase().includes(input.toUpperCase()));

      setSearch(input)
      setFilteredSearch(array);
    } else {
      setSearch(input);
      setFilteredSearch([]);
    }
  };

  const onViewRef = React.useRef(async ({ changed, viewableItems }) => {
    setPageNumber(changed[0].item);
    await DataController.saveLastPage(changed[0].item, _document.id);
  });

  const onPDFLoad = (_pdf) => {
    setLoading(false);
  }

  const onFlatlistScroll = async (event) => {
    if (pagingEnabled) {
      __DEV__ && console.log(event.nativeEvent.contentOffset.y);

      const index = Math.floor(event.nativeEvent.contentOffset.y / dimensions.height) + 1;
      setPageNumber(index);
      await DataController.saveLastPage(index, _document.id);
    }
  };

  const downloadDocument = () => {
    var element = document.createElement('a');
    const buffer = new Buffer(pdf.data);
    var blob = new Blob([buffer], { type: 'application/pdf' });
    var url = window.URL.createObjectURL(blob);
    var filename = _document['Document title'] ? `${_document['Document title']}.pdf` : 'Untitled.pdf'

    element.href = url;
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();

    window.URL.revokeObjectURL(url);
    closeShareMenuPopover();
  }

  const downloadText = (message, all, page) => {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/richtext;charset=utf-8,' + encodeURIComponent(message),
    );

    var filename = all ? 'Notes - ' : `Page ${page} Note - `;
    filename += _document['Document title'] ? `${_document['Document title']}` : 'Untitled Document';
    filename += '.doc';

    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', }} onLayout={(event) => measureContainer(event)}>
      <PDFAnnotationModal
        close={toggleModal}
        visible={modalVisible}
        currentDocument={_document}
        screenProps={screenProps.screenProps}
        downloadText={(message, all, page) => downloadText(message, all, page)}
      />
      {!loading ? (
        <View style={{ flex: 1, }}>
          {/* <Document file={pdf}> */}
          <Document
            file={pdf}
            loading={() => (
              <ActivityIndicator style={{ alignSelf: 'center' }} color="#382E73" />
            )}
            onLoadSuccess={(_pdf) => onPDFLoad(_pdf)}>
            <FlatList
              disableVirtualization
              snapToAlignment={'center'}
              pagingEnabled={pagingEnabled}
              decelerationRate={'fast'}
              data={pages}
              ref={pdfFlatListPaging}
              onScroll={(event) => onFlatlistScroll(event)}
              keyExtractor={item => `${item}`}
              ItemSeparatorComponent={() => <View style={{ height: 3 }} />}
              renderItem={renderItem}
              initialNumToRender={50}
              initialScrollIndex={initialPageNumber - 1}
              style={{ flex: 1, display: pagingEnabled ? undefined : 'none' }}
              contentContainerStyle={{ height: dimensions.height, width: dimensions.width, alignItems: 'center' }}
            />
            <FlatList
              disableVirtualization
              data={pages}
              ref={pdfFlatList}
              keyExtractor={item => `${item}`}
              ItemSeparatorComponent={() => <View style={{ height: 3 }} />}
              renderItem={renderItem}
              onViewableItemsChanged={onViewRef.current}
              viewabilityConfig={viewConfigRef}
              initialNumToRender={50}
              initialScrollIndex={initialPageNumber - 1}
              style={{ flex: 1, display: pagingEnabled ? 'none' : undefined }}
              contentContainerStyle={{ height: dimensions.height, width: dimensions.width, alignItems: 'center' }}
            />
          </Document>
        </View>
      ) : (
        <ActivityIndicator style={{ alignSelf: 'center' }} color="#382E73" />
      )}
      <ModalWeb
        isVisible={shareMenuVisible}
        onBackdropPress={closeShareMenuPopover}
        backdropOpacity={0.5}
        backdropColor={'#000000'}
        animationIn="fadeIn"
        animationOut="fadeOut"
        backdropTransitionOutTiming={0}
        hideModalContentWhileAnimating={true}
        style={{
          borderRadius: 10,
          alignSelf: 'center',
          justifyContent: 'center',
        }}>
        <View style={styles.deleteModalContainer}>
          <DocumentShareMenu
            document={_document}
            pagingEnabled={pagingEnabled}
            togglePaging={() => togglePaging()}
            closePopover={() => closeShareMenuPopover()}
            downloadDocument={() => downloadDocument()}
          />
        </View>
      </ModalWeb>
      {/* <Popover
        isVisible={shareMenuVisible}
        from={moreButton}
        onRequestClose={() => closeShareMenuPopover()}
        placement="bottom"
        backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
        popoverStyle={{ borderRadius: 10 }}>
        <DocumentShareMenu
          _document={_document}
          pagingEnabled={pagingEnabled}
          togglePaging={() => togglePaging()}
          closePopover={() => closeShareMenuPopover()}
        />
      </Popover> */}
      <View style={{ flex: 1, height: 70, backgroundColor: 'transparent', position: 'absolute', bottom: 0, width: '100%' }}>
        <TouchableOpacity hitSlop={{ top: 8, bottom: 8, left: 8, right: 8 }} activeOpacity={0.7} style={styles.notesButton} onPress={() => openNotes()}>
          <Text style={styles.notesText} allowFontScaling={false}>
            {`Page ${pageNumber}`}{numberOfNotes > 0 ? ` Notes (${numberOfNotes})` : ''}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity hitSlop={{ top: 8, bottom: 8, left: 8, right: 8 }} activeOpacity={0.7} style={styles.addNoteButton} onPress={() => addNoteToPage(pageNumber)}>
          <Text style={styles.addNoteText} allowFontScaling={false}>
            {'Add note'}
          </Text>
        </TouchableOpacity>
      </View>
      {searchActive && (
        <SearchBar
          search={search}
          clearSearch={() => clearSearch()}
          closeSearch={() => closeSearch()}
          document={_document}
          filteredSearch={filteredSearch}
          pagePressed={(_page) => pagePressed(_page)}
          searchActive={searchActive}
          searchChanged={(_input) => searchChanged(_input)}
        />
      )}
    </View>
  )
};

const SearchBar = ({ document, filteredSearch, search, searchChanged, closeSearch, clearSearch, searchActive, pagePressed }) => {
  const searchInput = useRef(null);

  useEffect(() => {
    if (searchActive) {
      try {
        searchInput.current.focus();
      } catch (error) {
        console.log(error);
      }
    }
  }, [searchActive]);

  const keyPressed = (key) => {
    if (key.key === 'Escape') {
      closeSearch();
    }
  };

  const renderItem = ({ item }) => {
    const page = item;
    return (
      <TouchableOpacity
        style={{
          height: 48,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          // paddingLeft: this.props.screenProps.screenProps.safeAreaInsets && this.props.screenProps.screenProps.safeAreaInsets.left,
          // paddingRight: this.props.screenProps.screenProps.safeAreaInsets && this.props.screenProps.screenProps.safeAreaInsets.right,
        }}
        onPress={() => pagePressed(page.page)}>
        <Text style={{
          fontFamily: 'Inter-SemiBold',
          fontSize: 17,
          fontWeight: Platform.OS === 'android' ? 'normal' : '600',
          fontStyle: 'normal',
          letterSpacing: -0.2,
          color: '#212529',
          marginStart: 16,
        }}>
          {`Page ${page.page}`}
        </Text>
        <View style={{ height: 44, width: 44, marginEnd: 4, justifyContent: 'center', alignItems: 'center' }}>
          <Image source={images.Button_Disclosure_Right} />
        </View>
      </TouchableOpacity>
    );
  };

  var searchContent = (
    <View style={{
      backgroundColor: '#FFFFFF',
      shadowColor: "#cfd3d6",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowRadius: 0,
      shadowOpacity: 1,
      elevation: 4,
    }}>
      <View style={{
        height: 82,
        paddingVertical: 16,
        // paddingLeft: this.props.screenProps.screenProps.safeAreaInsets && this.props.screenProps.screenProps.safeAreaInsets.left,
        // paddingRight: this.props.screenProps.screenProps.safeAreaInsets && this.props.screenProps.screenProps.safeAreaInsets.right,
        borderBottomWidth: 1,
        borderBottomColor: '#CFD3D6',
      }}>
        <Text
          style={{
            fontFamily: "Inter-Regular",
            fontSize: 15,
            fontWeight: "normal",
            fontStyle: "normal",
            lineHeight: 20,
            letterSpacing: -0.3,
            marginHorizontal: 16,
            color: "#7f8a93"
          }}
          numberOfLines={1}>
          {document['GS Number']}
        </Text>
        <Text
          style={{
            fontFamily: "Inter-SemiBold",
            fontSize: 17,
            fontWeight: Platform.OS === 'android' ? "normal" : "600",
            fontStyle: "normal",
            lineHeight: 24,
            letterSpacing: -0.2,
            color: "#212529",
            marginHorizontal: 16,
            marginTop: 6,
          }}
          numberOfLines={1}>
          {document['Document title']}
        </Text>
      </View>
      <FlatList
        renderItem={renderItem}
        data={filteredSearch}
        keyExtractor={(item, index) => `${item.page}`}
        ItemSeparatorComponent={() => (
          <View style={{ height: 1, backgroundColor: '#CFD3D6' }} />
        )}
        style={{
          maxHeight: 245,
        }}
        contentContainerStyle={{
          // flexGrow: -1
        }}
        bounces={false}
        keyboardShouldPersistTaps="handled"
        ListEmptyComponent={() => (
          <View
            style={{
              height: 48,
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Text style={{
              fontFamily: 'Inter-SemiBold',
              fontSize: 17,
              fontWeight: Platform.OS === 'android' ? 'normal' : '600',
              fontStyle: 'normal',
              letterSpacing: -0.2,
              color: '#212529',
            }}>
              {'No matches found'}
            </Text>
          </View>
        )}
      />
    </View>
  );

  var searchPopup = (
    <View style={{ width: '100%', position: 'absolute', top: 0, }}>
      <View style={{ height: 48, backgroundColor: '#8882AB', justifyContent: 'center', }}>
        <View style={{ flexDirection: 'row', paddingStart: 4, height: 36, borderRadius: 6, backgroundColor: '#FFFFFF', borderWidth: 1, borderColor: '#777199', alignItems: 'center', marginHorizontal: 16, }}>
          <Image style={{ tintColor: '#97A1A7', height: 30, width: 30 }} source={images.Button_PageSearch_Inactive} />
          <TextInput
            style={{
              flex: 1,
              marginHorizontal: 8,
              fontFamily: 'Inter-Regular',
              fontSize: 17,
              fontWeight: 'normal',
              fontStyle: 'normal',
              letterSpacing: -0.3,
              color: '#212529'
            }}
            ref={searchInput}
            underlineColorAndroid="transparent"
            value={search}
            onChangeText={(input) => searchChanged(input)}
            placeholder="Search to find page matches"
            placeholderTextColor="#97A1A7"
            onKeyPress={(event) => keyPressed(event.nativeEvent)}
          />
          {search !== '' && (
            <TouchableOpacity style={{ height: 36, width: 36, justifyContent: 'center', alignItems: 'center' }} onPress={() => clearSearch()}>
              <Image style={{ tintColor: '#97A1A7', height: 13, width: 13 }} source={images.close} />
            </TouchableOpacity>
          )}
        </View>
      </View>
      {(searchActive && search !== '') && searchContent}
    </View>
  );

  return (
    <>
      {searchPopup}
    </>
  );
};

const styles = StyleSheet.create({
  modal: {
    // marginTop: isTablet() ? 30 : 0,
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  deleteModalContainer: {
    alignSelf: 'center',
    borderRadius: 10,
    overflow: 'hidden',
    width: 200,
    backdropColor: '#FFFFFF',
  },
  modalHeaderContainer: {
    height: 48,
    backgroundColor: '#382E73',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopStartRadius: Platform.OS === 'web' || Platform.OS === 'android' ? 0 : 10,
    borderTopEndRadius: Platform.OS === 'web' || Platform.OS === 'android' ? 0 : 10,
  },
  modalHeaderContainerWeb: {
    height: 48,
    backgroundColor: '#382E73',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopStartRadius: Platform.OS === 'web' ? 0 : 10,
    borderTopEndRadius: Platform.OS === 'web' ? 0 : 10,
  },
  modalCloseButton: {
    position: 'absolute',
    left: Platform.OS === 'web' ? null : 16,
    right: Platform.OS === 'web' ? 16 : null,
  },
  modalCloseButtonWeb: {
    position: 'absolute',
    left: Platform.OS === 'web' ? null : 16,
    right: Platform.OS === 'web' ? 16 : null,
  },
  modalCloseButtonHitSlop: {
    top: 12,
    bottom: 12,
    left: 10,
    right: 10,
  },
  modalCloseButtonText: {
    fontFamily: 'Inter-SemiBold',
    fontSize: 17,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.2,
    color: '#F1F3F5',
  },
  modalScrollView: {
    backgroundColor: '#F3F2F7',
    height: Platform.OS === 'web' ? '100%' : null,
  },
  modalScrollViewContainer: {
    flexGrow: 1,
    flexDirection: 'column',
    paddingTop: 16,
    paddingBottom: isIphoneX() ? 100 : 44,
    marginHorizontal: 10,
    width: Platform.OS === 'web' ? '66%' : '100%',
    height: Platform.OS === 'web' ? '100%' : null,
    alignSelf: 'center',
    backgroundColor: '#F3F2F7',
  },
  modalView: {
    paddingHorizontal: Platform.OS === 'web' ? 0 : isTablet() ? 20 : 10,
  },
  modalScrollViewContainerWeb: {
    flexGrow: 1,
    flexDirection: 'column',
    marginHorizontal: 20,
    paddingBottom: Platform.OS === 'ios' ? 60 : 16,
    width: '66%',
    paddingTop: 16,
    alignSelf: 'center',
  },
  signInText: {
    fontFamily: 'Inter-SemiBold',
    fontSize: 15,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: -0.2,
    color: '#7F8A93',
  },
  userText: {
    fontFamily: 'Inter-SemiBold',
    fontSize: 17,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.2,
    color: '#212529',
    marginTop: 4,
  },
  button: {
    width: '100%',
    maxWidth: 354,
    height: 44,
    backgroundColor: '#A08BC1',
    borderRadius: 4,
    shadowColor: 'rgba(0, 0, 0, 0.24)',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    alignSelf: 'center',
    marginTop: 24,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 8,
  },
  buttonText: {
    fontFamily: 'Inter-Bold',
    fontSize: 17,
    fontWeight: Platform.OS === 'android' ? 'normal' : 'bold',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.2,
    textAlign: 'center',
    color: '#F1F3F5'
  },
  icon: {
    height: 44,
    width: 44,
  },
  scene: {
    flex: 1,
  },
  notesButton: {
    width: 140,
    height: 40,
    bottom: -6,
    left: Platform.OS === 'web' || isTablet() ? 24 : 16,
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    shadowColor: 'rgba(0, 0, 0, 0.08)',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowRadius: 16,
    shadowOpacity: 1,
    borderStyle: 'solid',
    justifyContent: 'center',
    elevation: 200,
    borderWidth: 2,
    borderColor: 'rgba(0, 0,0,0.1)',
  },
  notesText: {
    fontFamily: 'Inter-SemiBold',
    fontSize: 15,
    fontWeight: Platform.OS === 'ios' ? '600' : 'normal',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: -0.2,
    textAlign: 'center',
    color: '#382E73',
  },
  addNoteButton: {
    position: 'absolute',
    bottom: 24,
    right: Platform.OS === 'web' || isTablet() ? 24 : 16,
    width: 97,
    height: 40,
    borderRadius: 4,
    justifyContent: 'center',
    backgroundColor: '#9572CD',
    shadowColor: 'rgba(0, 0, 0, 0.08)',
    shadowOffset: {
      width: 0,
      height: 8
    },
    shadowRadius: 16,
    shadowOpacity: 1,
    borderStyle: 'solid',
    elevation: 200,
    borderWidth: 2,
    borderColor: 'rgba(0, 0,0,0.1)',
  },
  addNoteText: {
    fontFamily: 'Inter-SemiBold',
    fontSize: 15,
    fontWeight: Platform.OS === 'ios' ? '600' : 'normal',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: -0.2,
    textAlign: 'center',
    color: '#F1F3F5'
  },
  headerButton: {
    height: 44,
    width: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerIcon: {
    height: 44,
    width: 44,
  },
});

export default PDFViewerScreen;