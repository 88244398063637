import React, { Component, useMemo } from 'react';
import {
  View,
  Text,
  Button,
  StyleSheet,
  Image,
  Alert,
  TouchableOpacity,
  TouchableWithoutFeedback,
  NativeModules,
  Modal,
  Platform,
  Clipboard,
  StatusBar,
  ActivityIndicator,
  BackHandler,
} from 'react-native';
import moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import LoginScreen from './src/screens/LoginScreen';
import { NavigationContainer } from '@react-navigation/native';
import TimeTableContainerScreen from './src/screens/TimeTableContainerScreen';
import DocumentListScreen from './src/screens/DocumentListScreen';
import PDFViewerScreen from './src/screens/PDFViewerScreen';
import EditNoteScreen from './src/screens/EditNoteScreen';
import InfoScreen from './src/screens/InfoScreen';
import DataController from './src/controllers/DataController';
import SafeArea from 'react-native-safe-area';
import { isTablet } from 'react-native-device-info';
import images from './src/resources/images';
import AccountModal from './src/components/AccountModal';
import AuthenticationController from './src/controllers/AuthenticationController';
import { EventRegister } from 'react-native-event-listeners';
import { Helmet } from 'react-helmet';
import DocumentsContainerScreen from './src/screens/DocumentsContainerScreen';
import MySynodScreen from './src/screens/MySynodScreen';
import ContactMenuScreen from './src/screens/ContactMenuScreen';
import ContactScreen from './src/screens/ContactScreen';
// import { PDFDocument } from 'pdf-lib';
import RNBootSplash from 'react-native-bootsplash';
import { useMediaQuery } from "react-responsive";
import { ToastProvider } from 'react-native-toast-notifications';
import UpdatesModal from './src/components/UpdatesModal';
import DatabaseService from './src/controllers/DatabaseService';
import PushNotification from 'react-native-push-notification';
import PushNotificationIOS from '@react-native-community/push-notification-ios';
import FirebaseService from './src/controllers/FirebaseService';
// import '@expo/match-media'
import messaging from '@react-native-firebase/messaging';
import NetInfo from "@react-native-community/netinfo";
import 'react-native-gesture-handler';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();


const LoginStack = () => {
  return (
    <>
      <StatusBar backgroundColor="#F3F2F7" barStyle="dark-content" />
      <Stack.Navigator>
        <Stack.Screen name="Login" component={LoginScreen} options={{ title: 'Login' }} />
      </Stack.Navigator>
    </>
  );
};

const TimeTableStack = (screenProps) => {
  return (
    <>
      <StatusBar backgroundColor="#382E73" barStyle="light-content" />
      <Stack.Navigator>
        <Stack.Screen name="TimeTableContainer" options={{ title: 'General Synod' }}>
          {useScreenWithProps(TimeTableContainerScreen, screenProps)}
        </Stack.Screen>
        <Stack.Screen name="PDFViewer" options={{ title: 'General Synod' }}>
          {useScreenWithProps(PDFViewerScreen, screenProps)}
        </Stack.Screen>
        <Stack.Screen name="EditNote" options={{ title: 'General Synod' }}>
          {useScreenWithProps(EditNoteScreen, screenProps)}
        </Stack.Screen>
        <Stack.Screen name="Info" options={{ title: 'General Synod' }}>
          {useScreenWithProps(InfoScreen, screenProps)}
        </Stack.Screen>
      </Stack.Navigator>
    </>
  );
};

const DocumentsStack = (screenProps) => {
  return (
    <>
      <StatusBar barStyle="light-content" />
      <Stack.Navigator>
        <Stack.Screen name="DocumentsContainer" options={{ title: 'General Synod' }}>
          {useScreenWithProps(DocumentsContainerScreen, screenProps)}
        </Stack.Screen>
        <Stack.Screen name="DocumentList" options={{ title: 'General Synod' }}>
          {useScreenWithProps(DocumentListScreen, screenProps)}
        </Stack.Screen>
        <Stack.Screen name="PDFViewer" options={{ title: 'General Synod' }}>
          {useScreenWithProps(PDFViewerScreen, screenProps)}
        </Stack.Screen>
        <Stack.Screen name="EditNote" options={{ title: 'General Synod' }}>
          {useScreenWithProps(EditNoteScreen, screenProps)}
        </Stack.Screen>
      </Stack.Navigator>
    </>
  );
};

const MySynodStack = (screenProps) => {
  return (
    <>
      <StatusBar barStyle="light-content" />
      <Stack.Navigator>
        <Stack.Screen name="MySynod" options={{ title: 'General Synod' }}>
          {useScreenWithProps(MySynodScreen, screenProps)}
        </Stack.Screen>
        <Stack.Screen name="PDFViewer" options={{ title: 'General Synod' }}>
          {useScreenWithProps(PDFViewerScreen, screenProps)}
        </Stack.Screen>
        <Stack.Screen name="EditNote" options={{ title: 'General Synod' }}>
          {useScreenWithProps(EditNoteScreen, screenProps)}
        </Stack.Screen>
      </Stack.Navigator>
    </>
  );
};

const ContactStack = (screenProps) => {
  return (
    <>
      <StatusBar barStyle="light-content" />
      <Stack.Navigator>
        <Stack.Screen name="ContactMenu" options={{ title: 'General Synod' }}>
          {useScreenWithProps(ContactMenuScreen, screenProps)}
        </Stack.Screen>
        <Stack.Screen name="ContactPage" options={{ title: 'General Synod' }}>
          {useScreenWithProps(ContactScreen, screenProps)}
        </Stack.Screen>
        <Stack.Screen name="Info" options={{ title: 'General Synod' }}>
          {useScreenWithProps(InfoScreen, screenProps)}
        </Stack.Screen>
      </Stack.Navigator>
    </>
  );
};

const Tabs = (screenProps) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-device-width: 480px)' })

  return (
    <>
      <StatusBar barStyle="light-content" />
      <Tab.Navigator
        screenOptions={({ route }) => ({
          tabBarIcon: ({ focused, color, size, tintColor }) => {
            let icon;

            if (route.name === 'Timetable') {
              icon = focused ? images.Button_Timetable_Active : images.Button_Timetable_Inactive;
            } else if (route.name === 'Documents') {
              icon = focused ? images.Button_Documents_Active : images.Button_Documents_Inactive;
            } else if (route.name === 'My Synod') {
              icon = focused ? images.Button_MySynod_Active : images.Button_MySynod_Inactive;
            } else if (route.name === 'Contact') {
              icon = focused ? images.Button_Contact_Active : images.Button_Contact_Inactive;
            }

            return (
              <Image
                resizeMode={Platform.OS === 'web' ? 'center' : 'contain'}
                style={[styles.tabIcon, {
                  tintColor: focused ? '#382E73' : '#AAB4B9',
                  marginEnd: isTablet() || Platform.OS === 'web' ? isTabletOrMobile ? 0 : 16 : 0,
                }]}
                source={icon}
              />
            );
          },
          tabBarActiveTintColor: '#5C538C',
          tabBarInactiveTintColor: '#AAAAAA',
          tabBarStyle: [
            {
              display: 'flex',
            },
            null,
          ],
        })}
        tabBarOptions={{
          activeTintColor: '#5C538C',
          inactiveTintColor: '#AAA',
        }}>
        <Tab.Screen
          name="Timetable"
          options={{
            headerShown: false,
          }}
          children={() => (
            <TimeTableStack screenProps={screenProps} />
          )}
        />
        <Tab.Screen
          name="Documents"
          options={{
            headerShown: false,
          }}
          children={() => (
            <DocumentsStack screenProps={screenProps} />
          )}
        />
        <Tab.Screen
          name="My Synod"
          options={{
            headerShown: false,
          }}
          children={() => (
            <MySynodStack screenProps={screenProps} />
          )}
        />
        <Tab.Screen
          name="Contact"
          options={{
            headerShown: false,
          }}
          children={() => (
            <ContactStack screenProps={screenProps} />
          )}
        />
      </Tab.Navigator>
    </>
  )
};

type Props = {
  title: string;
}

type State = {
  synod: ?Object;
  timeTableItems: Object[];
  documents: Object[];
  diocese: Object[];
  pmmChoices: Object[];
  documentsToDownload: Object[];
  recentlyDownloadedDocuments: Object[];
  successfullyDownloadedDocuments: boolean;
  downloading: boolean;
  timeTableHasBeenUpdatedSinceLastDisplay: boolean;
  shouldShowSubjectPicker: boolean;
  contactForm: string;
  selectedSubject: Object;
  subjects: [];
  pdfModalVisible: boolean;
  editingPDF: boolean;
  openedDocument: ?Object;
  safeAreaInsets: Object;
  navigation: ?Object;
  user: Object;
  userInfo: Object;
}

export default class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      synod: null,
      timeTableItems: [],
      documents: [],
      diocese: [],
      pmmChoices: [],
      documentsToDownload: [],
      successfullyDownloadedDocuments: true,
      downloading: true,
      timeTableHasBeenUpdatedSinceLastDisplay: false,
      recentlyDownloadedDocuments: [],
      contactForm: 'General Query',
      shouldShowSubjectPicker: false,
      selectedSubject: this.defaultSubject(),
      subjects: [],
      accountModalVisible: false,
      pdfModalVisible: false,
      editingPDF: false,
      openedDocument: null,
      safeAreaInsets: { top: 0, left: 0, bottom: 0, right: 0 },
      navigation: null,
      loading: true,
      user: null,
      userInfo: null,
      updateVisible: false,
      updateTitle: '',
      updateMessage: '',
      updateButtons: [],
    };
  }

  async componentDidMount() {
    this.initializeFirebase();
    this.setupEventListeners();
    await this.loadUser();
    await DataController.copyDefaultFiles();
    await DataController.deleteDocumentsNoLongerInFeed();
    await this.displayLatestData();
    this.downloadData(false, false);

    if (Platform.OS === 'ios') {
      PushNotificationIOS.setApplicationIconBadgeNumber(0);
    }

    TouchableOpacity.defaultProps = TouchableOpacity.defaultProps || {};
    TouchableOpacity.defaultProps.activeOpacity = 0.5;

    // listen for safe area changes
    if (Platform.OS !== 'web') {
      var safeAreaInsetResult = await SafeArea.getSafeAreaInsetsForRootView();
      this.setState({ safeAreaInsets: safeAreaInsetResult.safeAreaInsets });
      SafeArea.addEventListener('safeAreaInsetsForRootViewDidChange', this.onSafeAreaInsetsForRootViewChange.bind(this));
    } else {
      this.setState({
        safeAreaInsets: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }
      });
    }

    // set up an interval to regularly check for updates
    var that = this;

    setInterval(() => {
      this.downloadData(false, false);
    }, 300000);
  }

  loadUser() {
    AsyncStorage.getItem('user').then(value => {
      AsyncStorage.getItem('userInfo').then(_value => {
        if (__DEV__ && value && _value) {
          console.log(value, _value);
        }

        this.setState({
          user: value ? JSON.parse(value) : null,
          userInfo: _value ? JSON.parse(_value) : null,
          loading: false,
        }, () => {
          this.setupNotesInterval();
          if (this.state.user) {
            this.setupNotifications();
          }

          if (Platform.OS === 'android') {
            RNBootSplash.hide({ fade: true });
          }
        });
      });
    });
  }

  async checkPermission() {
    const enabled = await FirebaseService.requestPermissions();

    if (enabled) {
      FirebaseService.subscribeToTopic();
    }
  }

  onNotification(notification) {
    try {
      Alert.alert(notification.title, notification.body, [{ text: 'Dismiss' }], {
        cancelable: false,
      });
      Platform.OS === 'ios' && PushNotificationIOS.setApplicationIconBadgeNumber(0);
    } catch (error) {
      console.log(error);
    }
  }

  async createNotificationListeners() {
    PushNotification.configure({
      onNotification: function (notification) {
        __DEV__ && console.log('NOTIFICATION', notification);

        if (Platform.OS === 'ios') {
          notification.finish(PushNotificationIOS.FetchResult.NoData);
        }
      },
      popInitialNotification: true,
      permissions: {
        alert: true,
        badge: true,
        sound: true,
      },
      requestPermissions: true,
    });
    /*
   * Triggered for data only payload in foreground
   * */
    this.messageListener = messaging().onMessage(async remoteMessage => {
      __DEV__ && console.log(remoteMessage);

      if (remoteMessage) {
        const notification = remoteMessage.notification;

        this.onNotification(notification);
      }
    })
  }

  async getUserInfo() {
    const { user } = this.state;

    if (user) {
      // console.log('accessToken: ', user.accessToken);
      let response = await fetch(
        `${AuthenticationController.getServerURL()}/synod/salesforce/getUserData`, {
        method: 'POST',
        body: JSON.stringify({
          // Change to false or remove to use development Salesforce
          IsProd: true,
          Token: user.accessToken,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        __DEV__ && console.log(response);
        let userJson = await response.json();

        if (__DEV__) {
          console.log(user);
          console.log(userJson);
        }

        this.setState({
          userInfo: userJson,
        });
      } else if ((response.status === 403 || response.status === 401) && Platform.OS !== 'web') {
        const refreshToken = await AsyncStorage.getItem('refreshToken');
        const result = await AuthenticationController.refreshToken(refreshToken);

        __DEV__ && console.log(result);

        if (result) {
          await AsyncStorage.setItem('user', JSON.stringify(result));
          this.getUserInfo();
        }
      }
    }
  }

  setupNotesInterval() {
    this.notesInterval = setInterval(() => {
      this.getLastModifiedTime();
    }, 30000);
  }

  async getLastModifiedTime() {
    const { user, userInfo } = this.state;
    const localTimestamp = await AsyncStorage.getItem('DocumentNotesTimestamp');

    const networkState1 = Platform.OS === 'web' ? { isConnected: window.navigator.onLine } : await NetInfo.fetch();

    __DEV__ && console.log(Platform.OS, 'getLastModifiedTime', user, userInfo);
    if (user && userInfo && networkState1.isConnected) {
      DatabaseService.getLastUpdateTime(userInfo.user_id).then(
        async (result) => {
          __DEV__ && console.log(Platform.OS, 'getLastModifiedTime', result);
          const timestamp = result.LastModifiedDate;

          if (localTimestamp) {
            __DEV__ && console.log(Platform.OS, timestamp, localTimestamp);
            __DEV__ && console.log(Platform.OS, new Date(timestamp) > new Date(localTimestamp));
            __DEV__ && console.log(Platform.OS, new Date(localTimestamp) > new Date(timestamp));
            // Get latest notes from server
            if (new Date(timestamp) > new Date(localTimestamp)) {
              __DEV__ && console.log('Server more recent', true);
              const networkState = Platform.OS === 'web' ? { isConnected: window.navigator.onLine } : await NetInfo.fetch();

              if (networkState.isConnected) {
                DatabaseService.getNotes(userInfo.user_id).then(async (body) => {
                  if (body.Notes) {
                    await AsyncStorage.setItem('DocumentNotes', body.Notes);
                    await AsyncStorage.setItem('DocumentNotesTimestamp', timestamp);
                    EventRegister.emit('notes/notes-updated');
                  }
                });
              }
            } else if (new Date(localTimestamp) > new Date(timestamp)) {
              __DEV__ && console.log('Local more recent', true);

              // Push local notes to server
              try {
                let notes = await AsyncStorage.getItem('DocumentNotes');

                if (notes) {
                  notes = JSON.parse(notes);
                  console.log(notes);
                  const networkState = Platform.OS === 'web' ? { isConnected: window.navigator.onLine } : await NetInfo.fetch();

                  if (networkState.isConnected) {
                    await DatabaseService.addNote(userInfo.user_id, notes);
                    DatabaseService.getNotes(userInfo.user_id).then(async (body) => {
                      if (body.Notes) {
                        await AsyncStorage.setItem('DocumentNotes', body.Notes);
                        await AsyncStorage.setItem('DocumentNotesTimestamp', timestamp);
                        EventRegister.emit('notes/notes-updated');
                      }
                    });
                  }
                }
              } catch (error) {
                console.log(error);
              }
            } else {
              const networkState = Platform.OS === 'web' ? { isConnected: window.navigator.onLine } : await NetInfo.fetch();

              if (networkState.isConnected) {
                DatabaseService.getNotes(userInfo.user_id).then(async (body) => {
                  if (body.Notes) {
                    await AsyncStorage.setItem('DocumentNotes', body.Notes);
                    await AsyncStorage.setItem('DocumentNotesTimestamp', timestamp);
                    EventRegister.emit('notes/notes-updated');
                  }
                });
              }
            }
          } else {
            DatabaseService.getNotes(userInfo.user_id).then(async (body) => {
              if (body.Notes) {
                await AsyncStorage.setItem('DocumentNotes', body.Notes);
                await AsyncStorage.setItem('DocumentNotesTimestamp', timestamp);
                EventRegister.emit('notes/notes-updated');
              }
            });
          }
        }, (error) => {
          error && console.log('getLastModifiedTime', error);
        });
    }
  }

  async logout() {
    const { user } = this.state;

    if (user) {
      if (Platform.OS === 'web') {
        await AsyncStorage.removeItem('user');
        await AsyncStorage.removeItem('refreshToken');
        await AsyncStorage.removeItem('userInfo');
        await AsyncStorage.removeItem('DocumentNotes');
        await AsyncStorage.removeItem('testModeEnabled');
        await AsyncStorage.removeItem('LastViewedPage');
        await AsyncStorage.removeItem('DocumentNotesTimestamp');

        this.setState({
          user: null,
          userInfo: null,
          accountModalVisible: false,
        });
      } else {
        const result = await AuthenticationController.revokeUser(user.accessToken);

        await AsyncStorage.removeItem('user');
        await AsyncStorage.removeItem('refreshToken');
        await AsyncStorage.removeItem('userInfo');
        await AsyncStorage.removeItem('DocumentNotes');
        await AsyncStorage.removeItem('testModeEnabled');
        await AsyncStorage.removeItem('LastViewedPage');
        await AsyncStorage.removeItem('DocumentNotesTimestamp');
        await AsyncStorage.getItem('fcmToken');

        this.setState({
          user: null,
          userInfo: null,
          accountModalVisible: false,
        });
      }

      this.messageListener = null;
    }
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  initializeFirebase() {
    if (Platform.OS !== 'web') {
      //  FirebaseService.initializeApp();
    }
  }

  setupEventListeners() {
    this.userListener = EventRegister.addEventListener('user/login', (value) => {
      this.setState({
        user: value,
      }, () => {
        // this.getUserInfo();
        this.getLastModifiedTime();
        if (value) {
          this.setupNotifications();
        }
      });
    })

    this.userInfoListener = EventRegister.addEventListener('user/user-info', (value) => {
      this.setState({
        userInfo: value,
      }, () => {

        this.getLastModifiedTime();
      });
    });
  }

  setupNotifications() {
    if (Platform.OS !== 'web') {
      this.checkPermission();
      this.createNotificationListeners();
    }
  }

  removeEventListeners() {
    EventRegister.removeEventListener(this.userListener);
    EventRegister.removeEventListener(this.userInfoListener);
    clearInterval(this.notesInterval);
    this.messageListener = null;
  }

  defaultSubject() {
    return { Name: '', Email: 'synod@churchofengland.org.uk', Form: 'General Query' };
  }

  async downloadData(shouldDisplayAlertIfUpdatedDocuments = true, shouldDisplayAlertIfUpToDate = true) {

    var that = this;

    this.setState({ downloading: true, successfullyDownloadedDocuments: true });

    try {

      var testModeEnabled = await AsyncStorage.getItem("testModeEnabled");
      // REMEMBER TO CHANGE BEFORE MAKING PRODUCTION BUILD
      testModeEnabled = testModeEnabled != null ? true : false;
      // testModeEnabled = true;

      var feed = 'live';
      if (testModeEnabled) {
        feed = 'test';
      }

      var timetableUpdated = await DataController.downloadSynodJSON(feed);

      if (timetableUpdated) {
        this.setState({ timeTableHasBeenUpdatedSinceLastDisplay: true });
      }

      await this.downloadDocuments(false);
      await this.displayLatestData();
      var documentsToDownload = await DataController.getDocumentsThatNeedToBeDownloaded(this.state.documents);

      this.setState({ documentsToDownload: documentsToDownload, successfullyDownloadedDocuments: true });

      if (documentsToDownload.length > 0) {

        if (shouldDisplayAlertIfUpdatedDocuments) {
          this.displayUpdatesDialog();
        }
      } else {

        if (shouldDisplayAlertIfUpToDate) {
          this.displayUpdatesDialog();
        }
      }

      DataController.updateLastCheckedDate();

    } catch (e) {
      this.setState({ successfullyDownloadedDocuments: false })
      console.log(e);
      FirebaseService.logEvent('download_failed');
    }

    this.setState({ downloading: false });
  }

  accountInfo() {
    this.setState({
      accountModalVisible: !this.state.accountModalVisible,
    });
  }

  async displayUpdatesDialog() {

    var that = this;

    var mostRecentDownloadDate = await DataController.getMostRecentDownloadDate();
    var mostRecentDateString = moment(mostRecentDownloadDate).format('Do MMM kk:mm');

    var lastCheckedForUpdatesDate = await DataController.getLastCheckedDate();
    var lastCheckedDateString = moment(lastCheckedForUpdatesDate).format('Do MMM kk:mm');

    if (lastCheckedForUpdatesDate == null) {
      lastCheckedDateString = "Never"
    }

    var timeTableLastUpdatedDate = await DataController.getTimeTableUpdatedDate();

    var dialogLastDisplayedDate = await DataController.getUpdateDialogLastDisplayedDate();

    var timeTableHasBeenUpdatedSinceLastDisplay = false;

    if (timeTableLastUpdatedDate > dialogLastDisplayedDate) {
      timeTableHasBeenUpdatedSinceLastDisplay = true;
    }



    if (this.state.successfullyDownloadedDocuments === false) {

      Alert.alert(
        "Couldn't download latest timetable",
        "Please check your internet and try again" + '\n\nLast updated: ' + mostRecentDateString + '\nLast checked: ' + lastCheckedDateString,
        [
          { text: 'Not Now', onPress: () => console.log('Cancel Pressed') },
          { text: 'Try again', onPress: () => that.downloadData() },
        ],
        { cancelable: false }
      )

    } else if (this.state.recentlyDownloadedDocuments.length > 0 || timeTableHasBeenUpdatedSinceLastDisplay) {

      var updateText = "";

      if (timeTableHasBeenUpdatedSinceLastDisplay) {
        updateText += "Timetable ";

        if (this.state.recentlyDownloadedDocuments.length > 0) {
          updateText += "and ";
        }
      }

      if (this.state.recentlyDownloadedDocuments.length > 0) {
        updateText += this.state.recentlyDownloadedDocuments.length;

        if (this.state.recentlyDownloadedDocuments.length === 1) {
          updateText += ' document ';
        } else {
          updateText += ' documents ';
        }
      }

      if (this.state.recentlyDownloadedDocuments.length > 1 || (timeTableHasBeenUpdatedSinceLastDisplay && this.state.recentlyDownloadedDocuments.length > 0)) {
        updateText += 'have ';
      } else {
        updateText += 'has ';
      }

      updateText += "been updated" + '\n\nLast updated: ' + mostRecentDateString + '\nLast checked: ' + lastCheckedDateString;

      Alert.alert(
        'Updates',
        updateText,
        [
          { text: 'OK', onPress: () => that.setState({ recentlyDownloadedDocuments: [] }) },
          { text: 'Check for updates', onPress: () => that.downloadData() },
        ],
        { cancelable: false }
      )

    } else {

      var title = "You are up to date";

      if (this.state.downloading) {
        title = "Downloading timetable and documents";
      }

      Alert.alert(
        title,
        '\nLast updated: ' + mostRecentDateString + '\nLast checked: ' + lastCheckedDateString,
        [
          { text: 'OK', onPress: () => console.log('OK Pressed') },
          { text: 'Check for updates', onPress: () => that.downloadData() },
        ],
        { cancelable: false }
      )
    }

    if (this.state.successfullyDownloadedDocuments) {
      DataController.updateUpdateDialogLastDisplayedDate();
      this.setState({ timeTableHasBeenUpdatedSinceLastDisplay: false });
    }
  }

  async displayUpdateModalWeb() {
    var that = this;

    var mostRecentDownloadDate = await DataController.getMostRecentDownloadDate();
    var mostRecentDateString = moment(mostRecentDownloadDate).format('Do MMM kk:mm');

    var lastCheckedForUpdatesDate = await DataController.getLastCheckedDate();
    var lastCheckedDateString = moment(lastCheckedForUpdatesDate).format('Do MMM kk:mm');

    if (lastCheckedForUpdatesDate == null) {
      lastCheckedDateString = "Never"
    }

    var timeTableLastUpdatedDate = await DataController.getTimeTableUpdatedDate();

    var dialogLastDisplayedDate = await DataController.getUpdateDialogLastDisplayedDate();

    var timeTableHasBeenUpdatedSinceLastDisplay = false;

    if (timeTableLastUpdatedDate > dialogLastDisplayedDate) {
      timeTableHasBeenUpdatedSinceLastDisplay = true;
    }

    if (this.state.successfullyDownloadedDocuments === false) {
      this.setState({
        updateTitle: "Couldn't download latest timetable",
        updateMessage: "Please check your internet and try again" + '\n\nLast updated: ' + mostRecentDateString + '\nLast checked: ' + lastCheckedDateString,
        updateButtons: [
          { text: 'Not Now', onPress: () => this.setState({ updateVisible: false }) },
          { text: 'Try again', onPress: () => this.setState({ updateVisible: false }, () => that.downloadData()) },
        ],
        updateVisible: true
      }, () => this.setState({ updateVisible: true }));
    } else if (this.state.recentlyDownloadedDocuments.length > 0 || timeTableHasBeenUpdatedSinceLastDisplay) {

      var updateText = "";

      if (timeTableHasBeenUpdatedSinceLastDisplay) {
        updateText += "Timetable ";

        if (this.state.recentlyDownloadedDocuments.length > 0) {
          updateText += "and ";
        }
      }

      if (this.state.recentlyDownloadedDocuments.length > 0) {
        updateText += this.state.recentlyDownloadedDocuments.length;

        if (this.state.recentlyDownloadedDocuments.length === 1) {
          updateText += ' document ';
        } else {
          updateText += ' documents ';
        }
      }

      if (this.state.recentlyDownloadedDocuments.length > 1 || (timeTableHasBeenUpdatedSinceLastDisplay && this.state.recentlyDownloadedDocuments.length > 0)) {
        updateText += 'have ';
      } else {
        updateText += 'has ';
      }

      updateText += "been updated" + '\n\nLast updated: ' + mostRecentDateString + '\nLast checked: ' + lastCheckedDateString;

      this.setState({
        updateTitle: 'Updates',
        updateMessage: updateText,
        updateButtons: [
          { text: 'OK', onPress: () => this.setState({ updateVisible: false, recentlyDownloadedDocuments: [] }) },
          { text: 'Check for updates', onPress: () => this.setState({ updateVisible: false }, () => that.downloadData()) },
        ],
      }, () => this.setState({ updateVisible: true }));
    } else {

      var title = "You are up to date";

      if (this.state.downloading) {
        title = "Downloading timetable and documents";
      }

      this.setState({
        updateTitle: title,
        updateMessage: '\nLast updated: ' + mostRecentDateString + '\nLast checked: ' + lastCheckedDateString,
        updateButtons: [
          { text: 'OK', onPress: () => this.setState({ updateVisible: false }) },
          { text: 'Check for updates', onPress: () => this.setState({ updateVisible: false }, () => that.downloadData()) },
        ],
      }, () => this.setState({ updateVisible: true }));
    }

    if (this.state.successfullyDownloadedDocuments) {
      DataController.updateUpdateDialogLastDisplayedDate();
      this.setState({ timeTableHasBeenUpdatedSinceLastDisplay: false });
    }
  }

  displayNotDownloadedDialog() {
    if (Platform.OS === 'web') {
      return;
    }

    var that = this;

    Alert.alert(
      'Document not downloaded',
      "This document hasn't been downloaded. Would you like to download it now?",
      [
        { text: 'Not Now', onPress: () => console.log('Cancel Pressed') },
        { text: 'Download', onPress: () => that.downloadDocuments(true) },
      ],
      { cancelable: false }
    )
  }

  async downloadDocuments(shouldDisplayAlert: boolean) {

    var that = this;

    var documentsToDownload = await DataController.getDocumentsThatNeedToBeDownloaded(this.state.documents);

    if (documentsToDownload.length > 0) {

      var results = await DataController.downloadDocuments(documentsToDownload);

      this.setState({ recentlyDownloadedDocuments: results.success });

      if (results.failed.length === 0) {

        if (shouldDisplayAlert) {
          Alert.alert(
            'Download completed',
            '',
            [
              { text: 'OK', onPress: () => console.log('OK Pressed') },
            ],
            { cancelable: false }
          )
        }

        this.setState({ documentsToDownload: [] });

      } else {
        if (shouldDisplayAlert) {
          Alert.alert(
            'Update failed',
            "Some updates couldn't be downloaded",
            [
              { text: 'OK', onPress: () => console.log('Cancel Pressed') },
              { text: 'Try again', onPress: () => that.downloadData(true) },
            ],
            { cancelable: false }
          )
        }

        this.setState({ documentsToDownload: results.failed, successfullyDownloadedDocuments: false });
        FirebaseService.logEvent('download_failed');
      }
    }
  }

  async displayLatestData() {

    var data = await DataController.getSynodJSON();

    var testModeEnabled = await AsyncStorage.getItem("testModeEnabled");

    // REMEMBER TO CHANGE BEFORE MAKING PRODUCTION BUILD
    testModeEnabled = testModeEnabled != null ? true : false;
    // testModeEnabled = true;

    if (data != null) {

      var currentSynod = data.synods[1];

      for (var synodIndex = 0; synodIndex < data.synods.length; synodIndex++) {

        var synod = data.synods[synodIndex];

        if (testModeEnabled) {

          if (synod.TestVersion != null && synod.TestVersion === true) {
            currentSynod = synod;
            break;
          }

        } else {

          if (synod.LiveVersion != null && synod.LiveVersion === true) {
            currentSynod = synod;
            break;
          }
        }
      }

      var timeTableItemsForSynod = [];
      var documentsForSynod = [];

      for (var timeTableItemIndex = 0; timeTableItemIndex < data.timeTableItems.length; timeTableItemIndex++) {

        var timeTableItem = data.timeTableItems[timeTableItemIndex];

        if (timeTableItem.SynodID === currentSynod.ID) {
          timeTableItemsForSynod.push(timeTableItem);
        }
      }

      var pmmChoices = [
        { Name: "Biblical Understanding of Marriage and Sexual Relationships" },
        { Name: "Review of the ‘Five Guiding Principles’" },
        { Name: "Liturgies for Same Sex Couples" },
        { Name: "Eucharistic Presidency" },
        { Name: "The Further Use of Churchyards" },
        { Name: "Safeguarding and Tendering for Professional Services" },
        { Name: "Funding of Bishops and Priests" },
        { Name: "Holy Communion Using Individual Cups" },
      ];

      if (data.pmmChoices != null) {
        pmmChoices = data.pmmChoices;
      }

      this.setState({ synod: currentSynod, timeTableItems: timeTableItemsForSynod, documents: data.documents, subjects: data.subjects, diocese: data.diocese, pmmChoices: pmmChoices });
    }
  }

  showSubjectPicker(shouldShow: boolean, contactForm: string) {

    if (contactForm !== this.state.contactForm) {

      if (contactForm === 'Request to speak') {

        for (var subjectIndex = 0; subjectIndex < this.state.subjects.length; subjectIndex++) {

          var subject = this.state.subjects[subjectIndex];

          if (subject.Form === 'Request to speak') {
            this.setState({ selectedSubject: subject });
          }
        }

      } else if (contactForm === "Private Members' Motions") {

        for (var subjectIndex = 0; subjectIndex < this.state.subjects.length; subjectIndex++) {

          var subject = this.state.subjects[subjectIndex];

          if (subject.Form === "Private Members' Motions") {
            this.setState({ selectedSubject: subject });
          }
        }

      } else {
        this.setState({ selectedSubject: this.defaultSubject() });
      }
    }

    this.setState({ shouldShowSubjectPicker: shouldShow, contactForm: contactForm });
  }

  subjectTapped(subject: Object) {
    this.setState({ selectedSubject: subject, shouldShowSubjectPicker: false })
  }

  async openPDF(document: Object, navigation: Object) {

    var isBookmarked = await DataController.isDocumentBookmarked(document);
    var note = await DataController.getNoteForDocument(document)
    var hasNote = false;
    if (note != null) {
      hasNote = true;
    }


    if (Platform.OS === 'ios') {
      const majorVersionIOS = parseInt(Platform.Version, 10);

      if (majorVersionIOS <= 10) {

        this.setState({ pdfModalVisible: true, openedDocument: document });

      } else {

        navigation.navigate('PDFViewer', {
          document: document,
          isBookmarked: isBookmarked,
          hasNote: hasNote
        });
      }

    } else {

      navigation.navigate('PDFViewer', {
        document: document,
        isBookmarked: isBookmarked,
        hasNote: hasNote
      });
    }
  }

  editNote() {

    this.setState({ editingPDF: true })
  }

  closeEditNote() {
    this.setState({ editingPDF: false })
  }

  closePDFModal() {
    this.setState({ pdfModalVisible: false })
  }

  onSafeAreaInsetsForRootViewChange(result) {
    // Called every time that safe area insets changed
    this.setState({ safeAreaInsets: result.safeAreaInsets });
    // { safeAreaInsets: { top: 0, left: 44, bottom: 21, right: 44 } }
  }

  render() {
    const { loading, user, userInfo, } = this.state;

    const screenProps = {
      synod: this.state.synod,
      timeTableItems: this.state.timeTableItems,
      documents: this.state.documents,
      diocese: this.state.diocese,
      pmmChoices: this.state.pmmChoices,
      documentsToDownload: this.state.documentsToDownload,
      recentlyDownloadedDocuments: this.state.recentlyDownloadedDocuments,
      successfullyDownloadedDocuments: this.state.successfullyDownloadedDocuments,
      timeTableHasBeenUpdatedSinceLastDisplay: this.state.timeTableHasBeenUpdatedSinceLastDisplay,
      shouldShowSubjectPicker: this.state.shouldShowSubjectPicker,
      displayUpdatesDialog: this.displayUpdatesDialog.bind(this),
      accountInfo: this.accountInfo.bind(this),
      displayNotDownloadedDialog: this.displayNotDownloadedDialog.bind(this),
      showSubjectPicker: this.showSubjectPicker.bind(this),
      displayUpdateModalWeb: this.displayUpdateModalWeb.bind(this),
      selectedSubject: this.state.selectedSubject,
      openPDF: this.openPDF.bind(this),
      safeAreaInsets: this.state.safeAreaInsets,
      user: this.state.user,
      userInfo: this.state.userInfo,
    };

    if (loading) {
      return (
        <>
          <StatusBar barStyle="dark-content" />
          <View style={styles.container}>
            <ActivityIndicator color="#212529" />
          </View>
        </>
      );
    }

    let fullscreenOverlay = null;
    let subjectPickerLayout = null;

    if (this.state.shouldShowSubjectPicker) {

      fullscreenOverlay = (
        <TouchableWithoutFeedback onPress={this.showSubjectPicker.bind(this, false, 'General Query')}>
          <View style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
            <View style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: '#000', opacity: 0.4 }} />
          </View>
        </TouchableWithoutFeedback>
      )

      let subjectsLayout = [];

      for (let subjectIndex = 0; subjectIndex < this.state.subjects.length; subjectIndex++) {

        let subject = this.state.subjects[subjectIndex];

        if (subject.Form === this.state.contactForm) {

          subjectsLayout.push(
            <View key={subject.Name}>
              <TouchableOpacity onPress={this.subjectTapped.bind(this, subject)}>
                <Text style={{ fontFamily: 'Inter-SemiBold', paddingVertical: 12, fontSize: 18, color: '#555', fontWeight: Platform.OS === 'android' ? 'normal' : '600', textAlign: 'center', backgroundColor: '#FFF' }}>{subject.Name}</Text>
              </TouchableOpacity>
              <View style={{ height: 1, backgroundColor: '#DEDEDE' }} />
            </View>
          );
        }
      }

      subjectPickerLayout = (
        <View style={{ position: 'absolute', bottom: 64, left: 10, right: 10, backgroundColor: '#FFF', borderRadius: 5, overflow: 'hidden' }}>
          <Text style={{ fontFamily: 'Inter-SemiBold', paddingVertical: 16, fontSize: 18, color: '#888', fontWeight: Platform.OS === 'android' ? 'normal' : '600', textAlign: 'center', backgroundColor: '#EAEDEE' }}>Please select a Subject</Text>
          {subjectsLayout}
        </View>
      )
    }

    return (
      <ToastProvider>
        {Platform.OS === 'web' && (
          <Helmet>
            <link rel="icon" href={images.web_icon} />
          </Helmet>
        )}
        {Platform.OS === 'web' && (
          <UpdatesModal
            visible={this.state.updateVisible}
            title={this.state.updateTitle}
            message={this.state.updateMessage}
            buttons={this.state.updateButtons}
            close={() => this.setState({ updateVisible: false })}
          />
        )}
        <AccountModal
          screenProps={screenProps}
          user={user}
          userInfo={userInfo}
          close={() => this.accountInfo()}
          logout={() => this.logout()} visible={this.state.accountModalVisible}
        />
        <NavigationContainer>
          {!user ? <LoginStack /> : <Tabs screenProps={screenProps} />}
        </NavigationContainer>
        {this.state.shouldShowSubjectPicker && (
          <View style={{ zIndex: 10, flex: 1, position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
            {fullscreenOverlay}
            {subjectPickerLayout}
          </View>
        )}
      </ToastProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F3F2F7',
  },
  loadingText: {
    fontFamily: 'Inter-Bold',
    fontSize: 17,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.2,
    textAlign: 'center',
    color: '#212529',
    marginTop: 16,
  },
  tabIcon: Platform.select({
    web: {
      height: 44,
      width: 44,
      marginEnd: isTablet() || Platform.OS === 'web' ? 16 : 0,
    },
    default: {
      // height: 44,
      // width: 44,
      marginBottom: isTablet() || Platform.OS === 'web' ? 0 : 4,
      marginTop: isTablet() || Platform.OS === 'web' ? 0 : 8,
      marginEnd: isTablet() || Platform.OS === 'web' ? 16 : 0,
    }
  }),
});

const useScreenWithProps = (PageComponent: React.FC<Props>, props: Props) => {
  return useMemo(
    () => (navigationProps, NavigationProps) => (
      <PageComponent {...navigationProps} {...props} />
    ),
    [PageComponent, props]
  );
};