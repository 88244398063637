/**
* Synod
* https://github.com/facebook/react-native
* @flow
*/
import React from 'react';
import {
  // AsyncStorage,
  Platform,
  Dimensions,
  PixelRatio,
} from 'react-native';
import ReactNativeBlobUtil from '../classes/RNFetchBlob';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import { PDFDocument } from 'pdf-lib';
import DatabaseService from './DatabaseService';
import NetInfo from "@react-native-community/netinfo";
import { S3_BUCKET_ENDPOINT } from '../config/constant';

var defaultSynodJSON = require('../resources/json/defaultSynod.json');

const DataController = {
  async copyDefaultFiles() {

    var that = this;

    return new Promise(async function (resolve, reject) {

      var synodJSON = await that.getSynodJSON();

      if (synodJSON == null) {

        if (Platform.OS !== 'web') {
          await ReactNativeBlobUtil.fs.writeFile(`${ReactNativeBlobUtil.fs.dirs.DocumentDir}/synod.json`, JSON.stringify(defaultSynodJSON), 'utf8')
        } else {
          AsyncStorage.setItem('synod.json', JSON.stringify(defaultSynodJSON));
        }

        var lastDownloadedDatesString = await AsyncStorage.getItem('LastDownloadDates');
        var lastDownloadedDates = JSON.parse(lastDownloadedDatesString);

        if (lastDownloadedDates == null) {
          lastDownloadedDates = {};
        }

        for (var documentIndex = 0; documentIndex < defaultSynodJSON.documents.length; documentIndex++) {

          var document = defaultSynodJSON.documents[documentIndex];
          var fileName = document.SynodID + "-" + document.ID + ".pdf";
          lastDownloadedDates[fileName] = new Date(document['Last updated']);
        }

        lastDownloadedDatesString = JSON.stringify(lastDownloadedDates);

        await AsyncStorage.setItem('LastDownloadDates', lastDownloadedDatesString);

        var lastUpdatedJSONDate = new Date(defaultSynodJSON.lastUpdated);
        await that.updateMostRecentDownloadDate(lastUpdatedJSONDate);

        console.log('Default files copied');
        resolve();

      } else {
        resolve();
      }
    });
  },

  async downloadSynodJSON(feed) {
    console.log('downloadSynodJSON feed', JSON.stringify(feed))
    var that = this;

    var currentJSON = await this.getSynodJSON();
    var currentJSONString = JSON.stringify(currentJSON);
 
    //  var feedUrl = 'https://synod.s3.amazonaws.com/synod-live.json'; //Client Live
    var feedUrl = S3_BUCKET_ENDPOINT + 'synod-live.json'; //Pocketapp Dev

    if (feed === 'test') {
      feedUrl =  S3_BUCKET_ENDPOINT +  'synod-test.json'; //'https://synod.s3.amazonaws.com/synod-test.json';
    }

    return new Promise(async function (resolve, reject) {
      if (Platform.OS === 'web') {
        fetch(feedUrl).then(async (response) => {

          const json = await response.json();
          AsyncStorage.setItem('synod.json', JSON.stringify(json));

          if (JSON.stringify(currentJSON.timeTableItems) === JSON.stringify(json.timeTableItems)) {
            resolve(false);
          } else {
            await that.updateTimeTableUpdatedDate();

            var newDate = new Date();
            await that.updateMostRecentDownloadDate(newDate);
            resolve(true);
          }
        });
      } else {
        ReactNativeBlobUtil
          .config({
            // add this option that makes response data to be stored as a file,
            // this is much more performant.
            // fileCache : true,
            // path: `${RNFetchBlob.fs.dirs.DocumentDir}/synod.json`
          })
          .fetch('GET', feedUrl, {
            'Cache-Control': 'no-store'
          })
          .then((res) => {
            console.log('downloadSynodJSON Response', JSON.stringify(res))
            if (res.info().status === 200) {

              ReactNativeBlobUtil.fs.writeFile(`${ReactNativeBlobUtil.fs.dirs.DocumentDir}/synod.json`, res.data, 'utf8')
                .then(async () => {
                  console.log('The file saved to ', res.path())

                  var newJSON = JSON.parse(res.data);

                  if (JSON.stringify(currentJSON.timeTableItems) === JSON.stringify(newJSON.timeTableItems)) {
                    resolve(false); // same data
                  } else {
                    // updated data
                    await that.updateTimeTableUpdatedDate();

                    var newDate = new Date();
                    await that.updateMostRecentDownloadDate(newDate);
                    resolve(true);
                  }
                })

            } else {
              console.log('Error downloading synod.json');
              reject();
            }
          }).catch(
            // Log the rejection reason
            (reason) => {
              console.log('Error downloading synod.json (' + reason + ').');
              reject();
            });
      }
    });
  },

  async getSynodJSON() {
    return new Promise(async function (resolve, reject) {
      if (Platform.OS !== 'web') {
        ReactNativeBlobUtil.fs.readFile(`${ReactNativeBlobUtil.fs.dirs.DocumentDir}/synod.json`)
          .then((data) => {

            var jsonData = JSON.parse(data);

            resolve(jsonData);
          }).catch(
            // Log the rejection reason
            (reason) => {
              console.log('Error: Failed to get synod.json (' + reason + ').');
              resolve(null);
            });
      } else {
        try {
          const data = await AsyncStorage.getItem('synod.json');
          resolve(data ? JSON.parse(data) : null);
        } catch (error) {
          console.log(error);
          resolve(null);
        }
      }
    });
  },

  async getTextFromPDFDocument(document: Object) {

    // return new Promise(function(resolve, reject) {

    //   var filePath = this.getFilePathForDocument(document);

    //   RNFetchBlob.fs.readFile(filePath)
    //   .then((data) => {

    //     pdf(dataBuffer).then(function(pdfData) {

    //       // number of pages
    //       console.log(pdfData.numpages);
    //       resolve(pdfData.numpages);

    //     }).catch(
    //       // Log the rejection reason
    //       (reason) => {
    //           console.log('Error: Failed to get pdf text (' + reason + ').');
    //           resolve(null);
    //     });

    //   }).catch(
    //     // Log the rejection reason
    //     (reason) => {
    //         console.log('Error: Failed to get document (' + reason + ').');
    //         resolve(null);
    //   });
    // });
  },

  async getDocumentsThatNeedToBeDownloaded(allDocuments: Object[]) {
    if (Platform.OS === 'web') {
      return [];
    }

    var that = this;

    return new Promise(async function (resolve, reject) {

      var documentsToDownload = [];

      var lastDownloadedDatesString = await AsyncStorage.getItem('LastDownloadDates');
      var lastDownloadedDates = JSON.parse(lastDownloadedDatesString);

      if (lastDownloadedDates == null) {
        lastDownloadedDates = {};
      }

      for (var documentIndex = 0; documentIndex < allDocuments.length; documentIndex++) {

        var document = allDocuments[documentIndex];

        var shouldDownload = true;

        // check what documents already exist on filesystem
        var fileName = document.SynodID + "-" + document.ID + ".pdf";
        var filePath = that.getFilePathForDocument(document);

        var exists = await ReactNativeBlobUtil.fs.exists(filePath);

        if (exists) {
          // check if lastDownloadedDate is older than lastUpdated date

          if (lastDownloadedDates[fileName] != null) {

            var lastDownloaded = lastDownloadedDates[fileName];
            var lastUpdated = document['Last updated'];

            if (lastDownloaded != null && lastUpdated != null) {
              var lastDownloadedDate = new Date(lastDownloaded);
              var lastUpdatedDate = new Date(lastUpdated);

              if (lastDownloadedDate >= lastUpdatedDate) {
                shouldDownload = false;
              }
            }
          }
        }

        if (shouldDownload) {
          documentsToDownload.push(document);
        }
      }

      resolve(documentsToDownload);
    });
  },

  async downloadDocuments(documentsToDownload: Object[]) {
    if (Platform.OS === 'web') {
      return;
    }

    var that = this;

    return new Promise(async function (resolve, reject) {

      var successfullyDownloadedDocuments = [];
      var failedDocuments = [];

      try {

        var lastDownloadedDatesString = await AsyncStorage.getItem('LastDownloadDates');
        var lastDownloadedDates = JSON.parse(lastDownloadedDatesString);

        if (lastDownloadedDates == null) {
          lastDownloadedDates = {};
        }

        // download all documents that need updating / don't exist
        for (var documentIndex = 0; documentIndex < documentsToDownload.length; documentIndex++) {

          var document = documentsToDownload[documentIndex];

          var documentURL = document['Website URL'];
          if (documentURL != null) {
            documentURL = documentURL.trim();
          }
          var fileName = document.SynodID + "-" + document.ID + ".pdf";
          var filePath = ReactNativeBlobUtil.fs.dirs.DocumentDir + "/" + fileName;

          try {

            var download = await ReactNativeBlobUtil.config({ fileCache: false, path: filePath }).fetch('GET', documentURL, { 'Cache-Control': 'no-store' });
            var status = download.info().status;

          } catch (error) {
            console.log(error);
          }

          if (status == 200) {
            lastDownloadedDates[fileName] = new Date();
            successfullyDownloadedDocuments.push(document);
          } else {
            failedDocuments.push(document);
          }
        }

        lastDownloadedDatesString = JSON.stringify(lastDownloadedDates);

        await AsyncStorage.setItem('LastDownloadDates', lastDownloadedDatesString);
        var newDate = new Date();
        await that.updateMostRecentDownloadDate(newDate);

      } catch (error) {
        console.log(error);
      }

      resolve({ success: successfullyDownloadedDocuments, failed: failedDocuments });

      // ?? delete documents no longer in feed ??
    });
  },

  async isDocumentDownloaded(document: Object) {
    if (Platform.OS === 'web') {
      return true;
    }

    var filePath = this.getFilePathForDocument(document);

    var exists = await ReactNativeBlobUtil.fs.exists(filePath);

    return exists;
  },

  async deleteDocumentsNoLongerInFeed() {
    if (Platform.OS !== 'web') {
      var files = await ReactNativeBlobUtil.fs.ls(ReactNativeBlobUtil.fs.dirs.DocumentDir);
      var synodJSON = await this.getSynodJSON();
      var filePathToDelete = ReactNativeBlobUtil.fs.dirs.DocumentDir;
      var documents = synodJSON.documents;

      for (var fileIndex = 0; fileIndex < files.length; fileIndex++) {

        var file = files[fileIndex];

        if (file.includes('.pdf')) {

          var fileFound = false;

          for (var documentIndex = 0; documentIndex < documents.length; documentIndex++) {

            var document = documents[documentIndex];
            var documentFileName = document.SynodID + "-" + document.ID + ".pdf";

            if (file === documentFileName) {
              fileFound = true;
            }
          }

          if (fileFound === false) {
            // file is on device but no longer in feed - so safe to delete
            var filePathToDelete = ReactNativeBlobUtil.fs.dirs.DocumentDir + '/' + file;
            await ReactNativeBlobUtil.fs.unlink(filePathToDelete);
          }
        }
      }
    }
  },

  async updateMostRecentDownloadDate(date: Date) {
    await AsyncStorage.setItem('MostRecentDownloadDate', JSON.stringify(date));
  },

  async getMostRecentDownloadDate() {
    var mostRecentDownloadDateString = await AsyncStorage.getItem('MostRecentDownloadDate');
    return JSON.parse(mostRecentDownloadDateString);
  },

  async updateLastCheckedDate() {
    var newDate = new Date();
    await AsyncStorage.setItem('LastCheckedForUpdates', JSON.stringify(newDate));
  },

  async getLastCheckedDate() {
    var lastCheckedForUpdatesDateString = await AsyncStorage.getItem('LastCheckedForUpdates');
    return JSON.parse(lastCheckedForUpdatesDateString);
  },

  async updateTimeTableUpdatedDate() {
    var newDate = new Date();
    await AsyncStorage.setItem('TimeTableUpdatedDate', JSON.stringify(newDate));
  },

  async getTimeTableUpdatedDate() {
    var timeTableUpdatedDateString = await AsyncStorage.getItem('TimeTableUpdatedDate');
    return JSON.parse(timeTableUpdatedDateString);
  },

  async updateUpdateDialogLastDisplayedDate() {
    var newDate = new Date();
    await AsyncStorage.setItem('UpdateDialogLastDisplayedDate', JSON.stringify(newDate));
  },

  async getUpdateDialogLastDisplayedDate() {
    var updateDialogLastDisplayedDateString = await AsyncStorage.getItem('UpdateDialogLastDisplayedDate');
    return JSON.parse(updateDialogLastDisplayedDateString);
  },

  getFilePathForDocument(document: Object) {
    if (Platform.OS === 'web') {
      return document['Website URL'];
    }

    var fileName = document.SynodID + "-" + document.ID + ".pdf";

    var filePath = ReactNativeBlobUtil.fs.dirs.DocumentDir + "/" + fileName;

    return filePath;
  },

  getDocumentFromRecordID(recordID: string, allDocuments: Object[]) {

    for (var documentIndex = 0; documentIndex < allDocuments.length; documentIndex++) {

      var document = allDocuments[documentIndex];

      if (document.id === recordID) {
        return document;
      }
    }

    return null;
  },

  async getBookmarkedDocuments(allDocuments: Object[]) {

    var bookmarkedDocuments = [];

    var bookmarksString = await AsyncStorage.getItem('Bookmarks');
    var bookmarks = JSON.parse(bookmarksString);

    if (bookmarks == null) {
      bookmarks = [];
    }

    for (var documentIndex = 0; documentIndex < allDocuments.length; documentIndex++) {

      var document = allDocuments[documentIndex];

      var fileName = document.SynodID + "-" + document.ID + ".pdf";

      if (bookmarks.includes(fileName)) {
        bookmarkedDocuments.push(document);
      }
    }

    return bookmarkedDocuments;
  },

  async isDocumentBookmarked(document: Object) {

    var bookmarksString = await AsyncStorage.getItem('Bookmarks');
    var bookmarks = JSON.parse(bookmarksString);

    if (bookmarks == null) {
      bookmarks = [];
    }

    var fileName = document.SynodID + "-" + document.ID + ".pdf";

    if (bookmarks.includes(fileName)) {
      return true;
    }

    return false;
  },

  async bookmarkDocument(document: Object) {

    var bookmarksString = await AsyncStorage.getItem('Bookmarks');
    var bookmarks = JSON.parse(bookmarksString);

    if (bookmarks == null) {
      bookmarks = [];
    }

    var fileName = document.SynodID + "-" + document.ID + ".pdf";

    if (!bookmarks.includes(fileName)) {
      bookmarks.push(fileName);
    }

    bookmarksString = JSON.stringify(bookmarks);

    await AsyncStorage.setItem('Bookmarks', bookmarksString);
  },

  async deleteBookmarkForDocument(document: Object) {

    var bookmarksString = await AsyncStorage.getItem('Bookmarks');
    var bookmarks = JSON.parse(bookmarksString);

    if (bookmarks == null) {
      bookmarks = [];
    }

    var fileName = document.SynodID + "-" + document.ID + ".pdf";

    if (bookmarks.includes(fileName)) {

      var index = bookmarks.indexOf(fileName);
      if (index !== -1) {
        bookmarks.splice(index, 1);
      }
    }

    bookmarksString = JSON.stringify(bookmarks);

    await AsyncStorage.setItem('Bookmarks', bookmarksString);
  },

  async getDocumentsWithNotes(allDocuments: Object[]) {

    var documentsWithNotes = [];

    var notesString = await AsyncStorage.getItem('DocumentNotes');
    var notes = notesString ? JSON.parse(notesString) : [];

    for (var documentIndex = 0; documentIndex < allDocuments.length; documentIndex++) {

      var document = allDocuments[documentIndex];

      var fileName = document.SynodID + "-" + document.ID + ".pdf";

      for (var noteIndex = 0; noteIndex < notes.length; noteIndex++) {

        var note = notes[noteIndex];

        if (note.fileName === fileName && note.notes.length > 0) {
          documentsWithNotes.push(document);
          break;
        }
      }
    }

    return documentsWithNotes;
  },

  async getNoteForDocument(document: Object) {

    var notesString = await AsyncStorage.getItem('Notes');
    var notes = JSON.parse(notesString);

    if (notes == null) {
      notes = [];
    }

    var fileName = document.SynodID + "-" + document.ID + ".pdf";

    for (var noteIndex = 0; noteIndex < notes.length; noteIndex++) {

      var note = notes[noteIndex];

      if (note.document === fileName) {
        return note;
      }
    }

    return null;
  },

  async saveNoteForDocument(document: Object, noteText: string) {

    var notesString = await AsyncStorage.getItem('Notes');
    var notes = JSON.parse(notesString);

    if (notes == null) {
      notes = [];
    }

    var fileName = document.SynodID + "-" + document.ID + ".pdf";

    var noteFound = false;
    var updatedDate = new Date();

    for (var noteIndex = 0; noteIndex < notes.length; noteIndex++) {

      var note = notes[noteIndex];

      if (note.document === fileName) {
        note.noteText = noteText;
        note.updatedDate = updatedDate;
        noteFound = true;
      }
    }

    if (noteFound === false) {
      var note = { document: fileName, noteText: noteText, updatedDate: updatedDate };
      notes.push(note);
    }

    notesString = JSON.stringify(notes);

    await AsyncStorage.setItem('Notes', notesString);
  },

  async deleteNoteForDocument(document: Object) {

    var notesString = await AsyncStorage.getItem('Notes');
    var notes = JSON.parse(notesString);

    if (notes == null) {
      notes = [];
    }

    var fileName = document.SynodID + "-" + document.ID + ".pdf";

    for (var noteIndex = 0; noteIndex < notes.length; noteIndex++) {

      var note = notes[noteIndex];

      if (note.document === fileName) {

        notes.splice(noteIndex, 1);

        break;
      }
    }

    notesString = JSON.stringify(notes);

    await AsyncStorage.setItem('Notes', notesString);
  },

  async addNewNote(document: Object, pageNumber: Number, noteText: String) {
    try {
      let notes: [] = await AsyncStorage.getItem('DocumentNotes');
      let user = await AsyncStorage.getItem('userInfo');

      if (user) {
        user = JSON.parse(user);
      }

      if (notes) {
        notes = JSON.parse(notes);
      } else {
        notes = [];
      }

      if (notes.length > 0) {
        let documentObject = notes.find((_note) => _note.documentId === document.id);

        if (documentObject) {
          let index = notes.findIndex((_note) => _note.documentId === documentObject.documentId);
          let userNotes = documentObject.notes;
          const newId = this.generateUniqueID();

          const noteObject = {
            page: pageNumber,
            noteText: encodeURI(noteText),
            id: newId,
            updated: Date.now(),
          }

          documentObject.notes.push(noteObject);
          notes.splice(index, 1, documentObject);

          await AsyncStorage.setItem('DocumentNotesTimestamp', new Date().toISOString());

          const networkState = Platform.OS === 'web' ? { isConnected: window.navigator.onLine } : await NetInfo.fetch();

          if (networkState.isConnected) {
            user && await DatabaseService.addNote(user.user_id, notes);
          }

          await AsyncStorage.setItem('DocumentNotes', JSON.stringify(notes));

          return newId;
        } else {
          const newId = this.generateUniqueID();

          const noteObject = {
            documentId: document.id,
            fileName: document.SynodID + "-" + document.ID + ".pdf",
            notes: [
              {
                page: pageNumber,
                noteText: encodeURI(noteText),
                id: newId,
                updated: Date.now(),
              }
            ]
          }

          notes.push(noteObject);

          await AsyncStorage.setItem('DocumentNotesTimestamp', new Date().toISOString());

          const networkState = Platform.OS === 'web' ? { isConnected: window.navigator.onLine } : await NetInfo.fetch();

          if (networkState.isConnected) {
            user && await DatabaseService.addNote(user.user_id, notes);
          }

          await AsyncStorage.setItem('DocumentNotes', JSON.stringify(notes));

          return newId;
        }
      } else {
        const newId = this.generateUniqueID();

        const noteObject = {
          documentId: document.id,
          fileName: document.SynodID + "-" + document.ID + ".pdf",
          notes: [
            {
              page: pageNumber,
              noteText: encodeURI(noteText),
              id: newId,
              updated: Date.now(),
            }
          ]
        }

        notes.push(noteObject);

        await AsyncStorage.setItem('DocumentNotesTimestamp', new Date().toISOString());

        const networkState = Platform.OS === 'web' ? { isConnected: window.navigator.onLine } : await NetInfo.fetch();

        if (networkState.isConnected) {
          user && await DatabaseService.addNote(user.user_id, notes);
        }

        await AsyncStorage.setItem('DocumentNotes', JSON.stringify(notes));

        return newId;
      }
    } catch (error) {
      console.log(error);
    }
  },

  async editNote(document: Object, noteId: String, noteText: String) {
    try {
      let notes: [] = await AsyncStorage.getItem('DocumentNotes');
      let user = await AsyncStorage.getItem('userInfo');


      if (user) {
        user = JSON.parse(user);
      }

      notes = JSON.parse(notes);

      let documentObject = notes.find((_note) => _note.documentId === document.id);

      let index = notes.findIndex((_note) => _note.documentId === documentObject.documentId);
      let userNotes = documentObject.notes;

      const noteToReplace = userNotes.find((_note) => _note.id === noteId);
      const noteToReplaceIndex = userNotes.findIndex((_note) => _note.id === noteId);

      const noteObject = {
        page: noteToReplace.page,
        noteText: encodeURI(noteText),
        id: noteId,
        updated: Date.now(),
      }

      documentObject.notes.splice(noteToReplaceIndex, 1, noteObject);
      notes.splice(index, 1, documentObject);

      await AsyncStorage.setItem('DocumentNotesTimestamp', new Date().toISOString());

      const networkState = Platform.OS === 'web' ? { isConnected: window.navigator.onLine } : await NetInfo.fetch();

      if (networkState.isConnected) {
        user && await DatabaseService.addNote(user.user_id, notes);
      }

      await AsyncStorage.setItem('DocumentNotes', JSON.stringify(notes));
    } catch (error) {
      console.log(error);
    }
  },

  async deleteNote(document: Object, noteId: String) {
    try {
      let notes: [] = await AsyncStorage.getItem('DocumentNotes');
      let user = await AsyncStorage.getItem('userInfo');


      if (user) {
        user = JSON.parse(user);
      }

      if (notes) {
        notes = JSON.parse(notes);
      } else {
        notes = [];
      }

      if (notes.length > 0) {
        let documentObject = notes.find((_note) => _note.documentId === document.id);

        if (documentObject) {
          let index = notes.findIndex((_note) => _note.documentId === documentObject.documentId);
          let userNotes = documentObject.notes;

          const noteToRemoveIndex = userNotes.findIndex((_note) => _note.id === noteId);

          if (noteToRemoveIndex > -1) {
            documentObject.notes.splice(noteToRemoveIndex, 1);
            notes.splice(index, 1, documentObject);
          }

          const networkState = Platform.OS === 'web' ? { isConnected: window.navigator.onLine } : await NetInfo.fetch();

          if (networkState.isConnected) {
            user && await DatabaseService.deleteNote(user.user_id, noteId);
          }

          await AsyncStorage.setItem('DocumentNotes', JSON.stringify(notes));
        }
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getAllNotes() {
    try {
      let notes = await AsyncStorage.getItem('DocumentNotes');

      if (notes) {
        notes = JSON.parse(notes);

        notes = notes && notes.map((_documentNoteInfo) => {
          let _notes = _documentNoteInfo.notes;
          _notes = _notes.map((_note) => ({
            ..._note,
            noteText: decodeURI(_note.noteText)
          }));

          return {
            ..._documentNoteInfo,
            notes: _notes
          }
        });
      }

      return notes ? notes : [];
    } catch (error) {
      console.log(error);
    }
  },

  generateUniqueID() {
    return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
  },

  async saveLastPage(page, documentId) {
    try {
      let lastPages: [] = await AsyncStorage.getItem('LastViewedPage');

      if (lastPages) {
        lastPages = JSON.parse(lastPages);
      } else {
        lastPages = []
      }

      const object = {
        id: documentId,
        page: page
      };

      if (lastPages.length > 0) {
        let lastPageObject = lastPages.find(item => item.id === documentId);

        if (lastPageObject) {
          let index = lastPages.findIndex(item => item.id === lastPageObject.id);

          lastPages.splice(index, 1, object);

        } else {
          lastPages.push(object);
        }

        await AsyncStorage.setItem('LastViewedPage', JSON.stringify(lastPages));
      } else {
        lastPages.push(object);

        await AsyncStorage.setItem('LastViewedPage', JSON.stringify(lastPages));
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getLastPage(documentId) {
    try {
      let lastPages = await AsyncStorage.getItem('LastViewedPage');

      if (lastPages) {
        lastPages = JSON.parse(lastPages);
        const lastPageObject = lastPages.find(item => item.id === documentId);

        return lastPageObject ? lastPageObject.page : 1;
      } else {
        return 1;
      }
    } catch (error) {
      console.log(error);
      return 1;
    }
  },
}

export default DataController;
