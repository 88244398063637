/**
 * Synod
 * https://github.com/facebook/react-native
 * @flow
 */
import React, { Component } from 'react'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TouchableHighlight,
  Platform,
  StyleSheet,
  Dimensions,
  ScrollView
} from 'react-native';
import { TabView, TabBar, SceneMap } from 'react-native-tab-view';

import DataController from '../controllers/DataController';

import moment from 'moment';
import images from '../resources/images';

type Props = {
  title: string;
  timeTableItems: Object[];
}

type State = {
  title: string;
  expandedItemIDs: number[];
}

export default class TimeTableScreen extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      expandedItemIDs: []
    };
  }

  async goToDocument(document: Object) {

    var documentDownloaded = await DataController.isDocumentDownloaded(document);
    var isBookmarked = await DataController.isDocumentBookmarked(document);

    if (documentDownloaded) {

      this.props.screenProps.openPDF(document, this.props.navigation);

    } else {
      this.props.screenProps.displayNotDownloadedDialog();
    }
  }

  timeTableItemExpandToggled(timeTableItem: Object) {

    var expandedItemIDs = this.state.expandedItemIDs;

    if (expandedItemIDs.includes(timeTableItem.ID)) {
      var index = expandedItemIDs.indexOf(timeTableItem.ID);
      if (index > -1) {
        expandedItemIDs.splice(index, 1);
      }
    } else {
      expandedItemIDs.push(timeTableItem.ID)
    }

    this.setState({ expandedItemIDs: expandedItemIDs })
  }

  render() {

    var timeTableLayouts = [];

    var previousStartTime = 'x';

    var dayDateString = null;
    var dateString = '';

    if (this.props.timeTableItems.length > 0) {
      var dayDate = this.props.timeTableItems[0].Date;
      var dayDateString = moment(dayDate).format('Do MMMM YYYY');
    }

    // console.log('this.props.timeTableItems', this.props.timeTableItems);
    for (var timeTableItemIndex = 0; timeTableItemIndex < this.props.timeTableItems.length; timeTableItemIndex++) {

      var timeTableItem = this.props.timeTableItems[timeTableItemIndex];

      var startTimeSeconds = timeTableItem['Start Time'];
      var startTime = '';

      if (startTimeSeconds != null) {

        var seconds = startTimeSeconds;
        var hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        var minutes = Math.floor(seconds / 60);

        if (hours.toString().length === 1) {
          hours = '0' + hours;
        }

        if (minutes < 10) {
          minutes = '0' + minutes;
        }

        startTime = hours + ":" + minutes;
      }

      var itemLayout = null;

      if (startTime != previousStartTime && startTime !== '' && timeTableItem.type !== "Rubric") {

        var timeKey = "time-" + timeTableItemIndex;

        var timeLayout = (
          <View key={timeKey} style={{ flexDirection: 'row', alignItems: 'center', marginTop: 16 }}>
            <Text
              style={{
                fontFamily: 'Inter-SemiBold',
                fontSize: 15,
                fontWeight: Platform.OS === 'android' ? 'normal' : '600',
                fontStyle: 'normal',
                letterSpacing: -0.2,
                width: 56,
                color: '#676B7B',
                textAlign: 'right',
              }}>
              {startTime}
            </Text>
            <View style={{ width: 1, height: 6, marginLeft: 6, backgroundColor: '#AAA' }}></View>
            <View style={{ flex: 1, height: 1, marginRight: 10, backgroundColor: '#AAA' }}></View>
          </View>
        );

        timeTableLayouts.push(timeLayout);

        previousStartTime = startTime;
      }

      if (timeTableItem.type === 'Ordinary Item') {

        // check if item has documents
        var itemDocuments = [];
        if (timeTableItem.Document != null) {

          for (var documentIndex = 0; documentIndex < timeTableItem.Document.length; documentIndex++) {

            var documentID = timeTableItem.Document[documentIndex];

            var document = DataController.getDocumentFromRecordID(documentID, this.props.documents);
            // get document from its ID

            if (document != null) {
              itemDocuments.push(document);
            }
          }
        }

        var documentsLayout = [];
        var subtitleLayout = null;
        if (this.state.expandedItemIDs.includes(timeTableItem.ID)) {

          for (var documentIndex = 0; documentIndex < itemDocuments.length; documentIndex++) {

            var document = itemDocuments[documentIndex];

            var gsNumber = 'GS';

            if (document != null && document['GS Number'] != null) {
              gsNumber = document['GS Number'];
            }

            var aDocumentLayout = (
              <View key={document.id} style={{ borderLeftWidth: 3, borderLeftColor: '#A08BC1' }}>
                <View style={{ height: 1, backgroundColor: '#DDD' }} />
                <TouchableOpacity onPress={this.goToDocument.bind(this, document)}>
                  <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10, paddingVertical: 20 }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                      <Image source={images.Document_Inactive} style={{}} />
                      <View style={{ flex: 1, marginLeft: 6 }}>
                        <Text style={{ color: '#777' }}>{gsNumber}</Text>
                        <Text style={{ marginTop: 6, fontSize: 18, fontWeight: '500', color: '#333' }}>{document['Document title']}</Text>
                      </View>
                    </View>
                    <Image source={images.Button_Disclosure_Right} style={{ marginLeft: 8 }} />
                  </View>
                </TouchableOpacity>
              </View>
            )

            documentsLayout.push(aDocumentLayout);
          }

          if (timeTableItem['Sub Title'] != null) {
            subtitleLayout = (
              <Text style={{ paddingHorizontal: 10, paddingBottom: 10, fontSize: 17, color: '#555' }}>{timeTableItem['Sub Title']}</Text>
            )
          }
        }

        var canBeExpanded = false;
        var expandDisclosureLayout = null;
        var mainCellTextColor = '#676B7B';

        if (itemDocuments.length > 0 || timeTableItem['Sub Title']) {
          // can be expanded
          canBeExpanded = true;
          mainCellTextColor = '#333';

          var imageSource = images.Button_Disclosure_Expand

          if (this.state.expandedItemIDs.includes(timeTableItem.ID)) {
            imageSource = images.Button_Disclosure_Collapse
          }

          expandDisclosureLayout = (
            <Image source={imageSource} style={{ marginRight: 10 }} />
          )
        }

        var mainCellLayout = (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text
              style={{
                flex: 1,
                fontFamily: 'Inter-SemiBold',
                fontWeight: Platform.OS === 'android' ? 'normal' : '600',
                fontStyle: 'normal',
                letterSpacing: -0.2,
                paddingHorizontal: 10,
                paddingVertical: 16,
                fontSize: 17,
                color: mainCellTextColor,
              }}>
              {timeTableItem.Title}
            </Text>
            {expandDisclosureLayout}
          </View>
        )

        if (canBeExpanded) {
          mainCellLayout = (
            <TouchableHighlight underlayColor='#EEE' key={timeTableItemIndex} onPress={this.timeTableItemExpandToggled.bind(this, timeTableItem)}>
              {mainCellLayout}
            </TouchableHighlight>
          )
        }

        itemLayout = (
          <View key={timeTableItemIndex} style={{ marginTop: 12, marginLeft: 60, marginRight: 10, borderRadius: 5, shadowColor: '#000', shadowOffset: { width: 0, height: 1 }, shadowRadius: 1, shadowOpacity: 0.2, backgroundColor: '#FFF', elevation: 2, }}>
            {mainCellLayout}
            {subtitleLayout}
            {documentsLayout}
          </View>
        )

        timeTableLayouts.push(itemLayout);

      } else if (timeTableItem.type === 'Rubric') {

        itemLayout = (
          <View key={timeTableItemIndex} style={{ flexDirection: 'row', alignItems: 'center', marginTop: 16 }}>
            <View style={{ flex: 1, height: 1, marginLeft: 12, backgroundColor: '#AAA' }}></View>
            <Text
              style={{
                fontFamily: 'Inter-SemiBold',
                fontWeight: Platform.OS === 'android' ? 'normal' : '600',
                fontStyle: 'normal',
                letterSpacing: -0.2,
                marginHorizontal: 8,
                fontSize: 15,
                color: '#676B7B',
                textAlign: 'center'
              }}>
              {timeTableItem.Title}
            </Text>
            <View style={{ flex: 1, height: 1, marginRight: 10, backgroundColor: '#AAA' }}></View>
          </View>
        )

        timeTableLayouts.push(itemLayout);
      }
    }

    return (
      <View style={{ flex: 1, backgroundColor: '#F3F2F7', paddingLeft: this.props.screenProps.safeAreaInsets.left, paddingRight: this.props.screenProps.safeAreaInsets.right }}>

        <ScrollView>
          <Text
            style={{
              fontFamily: 'Inter-SemiBold',
              fontWeight: Platform.OS === 'android' ? 'normal' : '600',
              fontStyle: 'normal',
              letterSpacing: -0.2,
              marginTop: 20,
              fontSize: 15,
              textAlign: 'center',
              paddingHorizontal: 20,
              color: '#676B7B'
            }}>
            {dayDateString}
          </Text>
          {timeTableLayouts}
          <Text
            style={{
              fontFamily: 'Inter-SemiBold',
              fontWeight: Platform.OS === 'android' ? 'normal' : '600',
              fontStyle: 'normal',
              letterSpacing: -0.2,
              marginTop: 20,
              fontSize: 16,
              textAlign: 'center',
              paddingHorizontal: 20,
              color: '#676B7B'
            }}>
            {'Please note that all timings are indicative unless stated '}
            <Text
              style={{
                // fontFamily: 'Inter-SemiBold',
                // fontStyle: 'italic'
              }}>
              {'"Not Later Than"'}
            </Text>
          </Text>
          <View style={{ height: 40 }} />
        </ScrollView>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  scene: {
    flex: 1,
  },
});