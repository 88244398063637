
export const API_URL = 'https://synod-server.development.pocketappserver.net'   //PA developemnet
//export const API_URL = 'https://synod-server.uat.everydayfaithapp.com'   //Client PA created UAT
//export const API_URL = 'https://cofe.aimernginx.co.uk' //Client production
//export const API_URL   = 'https://api.generalsynodapp.com'
//export const API_URL = 'https://synod-server.uat.generalsynodapp.com' ////Client PA created UAT

//Previous salesforce URL and clinet id 
export const API_SALESFORCE_URL = 'https://churchofengland2.my.site.com/synod' //'https://churchofengland.force.com/synod'
export const SALESFORCE_CLIENT_ID  = '3MVG9sh10GGnD4DutP5NQyGjKGW_paJ3KVtr7ANzIKwvfToH38kwsKZhi7_0gGCqJTVaOrX5rDYLdFbUlWmCS'
export const DIRECTION_URL_CALLBACK  = 'com.aimermedia.synod:/callback'

//Current salesforce URL and clinet id 
// export const API_SALESFORCE_URL = 'https://churchofengland2--uat.sandbox.my.site.com/synod'
// export const SALESFORCE_CLIENT_ID  = '3MVG9sh10GGnD4DutP5NQyGjKGW_paJ3KVtr7ANzIKwvfToH38kwsKZhi7_0gGCqJTVaOrX5rDYLdFbUlWmCS'
// export const DIRECTION_URL_CALLBACK  = 'com.aimermedia.synod:/callback'

export const S3_BUCKET_ENDPOINT = 'https://synod-data-sync-production.s3.eu-west-1.amazonaws.com/' //PA S3 bucket
//export const S3_BUCKET_ENDPOINT = 'https://synod.s3.amazonaws.com/'  //Client S3 bucket
//export const S3_BUCKET_ENDPOINT = 'https://synod-data-sync.s3.eu-west-2.amazonaws.com/' //Client S3 bucket UAT
//export const S3_BUCKET_ENDPOINT = 'https://synod-data-sync-production.s3.amazonaws.com/' //Client S3 bucket Production
//export const S3_BUCKET_ENDPOINT = 'https://synod-data-sync-uat.s3.eu-west-2.amazonaws.com/' //Client S3 bucket UAT

export const API_SALESFORCE_URL_TO_CHECK_MEMBER = 'churchofengland2.my.site.com' //'churchofengland2--uat.sandbox.my.site.com' ////"churchofengland2.my.site.com"

export const API_VERSION = ''
export const API_ENDPOINT = API_URL;
export const API_SALESFORCE_ENDPOINT = API_SALESFORCE_URL;
