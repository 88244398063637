import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import { API_ENDPOINT } from '../config/constant';

const DatabaseService = {
  serverURL() {
    // Swap to localhost to use local server
    return  API_ENDPOINT; //'https://cofe.aimernginx.co.uk';
    return 'http://localhost:8005';
  },

  async getLastUpdateTime(userId) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.serverURL()}/synod/notes/getLastUpdated`;
        let response = null;

        const body = {
          ...(userId && { UserID: userId }),
        };

        try {
          response = await fetch(url, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          });
        } catch (error) {
          reject(error);
        }

        let responseJson = await response.json();
        __DEV__ && console.log(responseJson);

        if (responseJson.success) {
          resolve(responseJson.body);
        } else {
          reject(responseJson.body);
        }
      } catch (error) {
        reject(error);
      }
    });
  },

  async addNote(userId, notes) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.serverURL()}/synod/notes/addNote`;
        let response = null;

        const body = {
          ...(userId && { UserID: userId }),
          ...(notes && { Notes: notes }),
        };

        __DEV__ && console.log(userId, notes, body);

        response = await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });

        let responseJson = await response.json();

        if (responseJson.success) {
          resolve(responseJson.body.affectedRows);
        } else {
          reject(responseJson.body);
        }
      } catch (error) {
        reject(error);
      }
    });
  },

  async getNotes(userId) {
    console.log("getNotes userId",JSON.stringify(userId));

    return new Promise(async (resolve, reject) => {
      const url = `${this.serverURL()}/synod/notes/getNotes`;
      let response = null;

      const body = {
        ...(userId && { UserID: userId }),
      };

      try {
        response = await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
      } catch (error) {
        reject(error);
      }

      let responseJson = await response.json();
       console.log("getNotes responseJson",JSON.stringify(responseJson));
      if (responseJson.success) {
        resolve(responseJson.body);
      } else {
        reject(responseJson.body);
      }
    });
  },

  async deleteNote(userId, noteId) {
    return new Promise(async (resolve, reject) => {
      const url = `${this.serverURL()}/synod/notes/deleteNote`;
      let response = null;

      const body = {
        ...(userId && { UserID: userId }),
        ...(noteId && { NoteID: noteId }),
      };

      try {
        response = await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
      } catch (error) {
        reject(error);
      }

      let responseJson = await response.json();

      if (responseJson.success) {
        resolve(responseJson.body);
      } else {
        reject(responseJson.body);
      }
    });
  }
};

export default DatabaseService;
